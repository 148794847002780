import React, { useMemo, useState } from "react";
import "./Companies.scss";
import { Table } from "antd";
import { useGetCompanies } from "../../../services/company";
import { useCompaniesFilters } from "./hooks/useCompaniesFilters";
import { getColumns } from "./table/columns";
import { useTablePagination } from "../../../hooks/useTablePagination";
import { SearchInput } from "../../../components";
import { AddButton } from "../../../components";
import { AddCompanyModal } from "./AddCompanyModal/AddCompanyModal";
import { useDebouncedCallback } from "use-debounce";

export const Companies = () => {
  const { pagination, resetCurrentPage } = useTablePagination(10);
  const [searchText, setSearchText] = useState<string | undefined>();
  const {
    data: companies,
    isLoading: companiesLoading,
    totalItemCount,
    refetch: refetchCompanies,
  } = useGetCompanies({
    pageNumber: pagination.currentPage,
    pageSize: pagination.pageSize,
    searchText,
  });
  const { filteredCompanies } = useCompaniesFilters(companies);
  const [addCompanyModalOpen, setAddCompanyModalOpen] =
    useState<boolean>(false);

  const tableDataSource = useMemo(() => {
    return filteredCompanies ?? [];
  }, [filteredCompanies]);

  const tableColumns = useMemo(() => {
    return getColumns();
  }, []);

  const onCompanySearch = useDebouncedCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (pagination.currentPage !== 1) {
        resetCurrentPage();
      }
      setSearchText(e.target.value);
    },
    200,
  );

  return (
    <div className={"companies-container"}>
      <div className={"companies-filters-container"}>
        <SearchInput onChange={onCompanySearch} />
        <AddButton onClick={() => setAddCompanyModalOpen(true)} />
      </div>
      <Table
        className={"companies-table"}
        dataSource={tableDataSource}
        columns={tableColumns}
        loading={companiesLoading}
        pagination={{ ...pagination, total: totalItemCount || 1 }}
      />
      <AddCompanyModal
        modalOpen={addCompanyModalOpen}
        onCloseModal={() => setAddCompanyModalOpen(false)}
        refetchCompanies={refetchCompanies}
      />
    </div>
  );
};
