import React, { useEffect } from "react";
import "./EditNameModal.scss";
import { Checkbox, Col, Form, Input, Modal, Row } from "antd";
import { formValidation } from "../../../../../utils/formValidation";

interface IEditNameModal {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentFirstName: string;
  currentLastName: string;
  currentCompanyName: string;
  isCompany: boolean;
  isTrusted: boolean;
  onSubmit?: (values: any, afterSubmit?: () => void) => void;
  loading?: boolean;
}

export const EditNameModal: React.FC<IEditNameModal> = ({
  modalOpen,
  setModalOpen,
  currentFirstName,
  currentLastName,
  currentCompanyName,
  isCompany,
  isTrusted,
  onSubmit,
  loading,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (!modalOpen) {
      form.resetFields();
    }
  }, [form, modalOpen]);

  useEffect(() => {
    if (modalOpen) {
      if (isCompany) {
        form.setFieldsValue({
          firstName: currentFirstName,
          lastName: currentLastName,
        });
      } else {
        form.setFieldsValue({
          firstName: currentFirstName,
          lastName: currentLastName,
        });
      }
      form.setFieldsValue({
        isTrusted: isTrusted,
      });
    }
  }, [
    currentFirstName,
    currentLastName,
    isTrusted,
    currentCompanyName,
    isCompany,
    modalOpen,
  ]);

  const handleSubmit = (values: any) => {
    onSubmit?.(values, () => setModalOpen(false));
  };

  const renderNameFields = () => {
    if (isCompany) {
      return (
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name={"companyName"} rules={formValidation.required}>
              <Input
                className={"edit-doer-input"}
                placeholder={"კომპანიის სახელი"}
              />
            </Form.Item>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name={"firstName"} rules={formValidation.required}>
              <Input className={"edit-doer-input"} placeholder={"სახელი"} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={"lastName"} rules={formValidation.required}>
              <Input className={"edit-doer-input"} placeholder={"გვარი"} />
            </Form.Item>
          </Col>
        </Row>
      );
    }
  };

  return (
    <Modal
      open={modalOpen}
      className={"edit-name-modal"}
      title={"სახელის რედაქტირება"}
      onOk={form.submit}
      okText={"დასტური"}
      cancelText={"დახურვა"}
      onCancel={() => setModalOpen(false)}
      maskClosable
      destroyOnClose
      okButtonProps={{
        loading: loading,
      }}
    >
      <Form form={form} onFinish={handleSubmit}>
        {renderNameFields()}
        <Row>
          <Col span={24}>
            <Form.Item name={"isTrusted"} valuePropName={"checked"}>
              <Checkbox>სანდო შემსრულებელი</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
