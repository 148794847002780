import { useMemo } from "react";
import {
  BUSINESS_TASK_STATUS_TYPES,
  BusinessTaskStatus,
  BusinessTaskStatusItem,
  businessTaskStatusTypes,
} from "../../../../services/generalTypes";

type UseBusinessTaskStatus = {
  statusesArr: BusinessTaskStatusItem[];
  selectedTab: BusinessTaskStatus;
  businessTaskStatuses: Array<number>;
};

export const useBusinessTaskStatus = (
  paramsStatus: string | undefined,
): UseBusinessTaskStatus => {
  const statusesArr = useMemo((): BusinessTaskStatusItem[] => {
    return businessTaskStatusTypes.slice(0, 3);
  }, [businessTaskStatusTypes]);

  const selectedTab = useMemo((): BusinessTaskStatus => {
    if (paramsStatus) {
      const status = statusesArr.find((item) => item.name === paramsStatus);
      if (status) return status.name;
    }
    return BUSINESS_TASK_STATUS_TYPES.INCOMING;
  }, [paramsStatus, statusesArr]);

  const businessTaskStatuses = useMemo(() => {
    const statusId =
      businessTaskStatusTypes.find((item) => item.name === selectedTab)?.id ||
      1;

    switch (selectedTab) {
      case BUSINESS_TASK_STATUS_TYPES.COMPLETED:
        return [statusId, 4]; //@TODO temporary
      default:
        return [statusId];
    }
  }, [selectedTab]);

  return {
    statusesArr,
    selectedTab,
    businessTaskStatuses,
  };
};
