import React, { useMemo, useState } from "react";
import "./BusinessTask.scss";
import {
  BusinessTaskDetails,
  useUpdateBusinessTask,
} from "../../../../services/task";
import {
  BankOutlined,
  ClockCircleOutlined,
  DollarCircleOutlined,
  EnvironmentOutlined,
  MailOutlined,
  PhoneOutlined,
  SwapOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  formatPhoneNumber,
  getBusinessStatusColorByName,
  getBusinessStatusNameById,
} from "../../../../utils/helpers";
import { ContainerWithEdit, InfoDetailItem } from "../../../../components";
import moment from "moment";
import { SectionTitle } from "../../../../components";
import { businessTaskStatusTypes } from "../../../../services/generalTypes";
import { EditTitleModal } from "../../Tasks/ActionModals/EditTitleModal/EditTitleModal";
import { message } from "antd";
import { useParams } from "react-router";
import { EditBusinessTaskDetailsModal } from "../ActionModals/EditBusinessTaskDetailsModal/EditBusinessTaskDetailsModal";
import { EditBusinessTaskDescriptionModal } from "../ActionModals/EditBusinessTaskDescriptionModal/EditBusinessTaskDescriptionModal";

type BusinessTaskProps = {
  businessTask: BusinessTaskDetails;
  actionButtons?: JSX.Element;
  refetchBusinessTask: () => Promise<any>;
};

export const BusinessTask: React.FC<BusinessTaskProps> = ({
  businessTask,
  actionButtons,
  refetchBusinessTask,
}) => {
  const { id: businessTaskId } = useParams();
  const { mutate: updateBusinessTask, isLoading: isUpdateBusinessTaskLoading } =
    useUpdateBusinessTask();
  const [editTitleModalOpen, setEditTitleModalOpen] = useState<boolean>(false);
  const [
    editBusinessTaskDetailsModalOpen,
    setEditBusinessTaskDetailsModalOpen,
  ] = useState<boolean>(false);
  const [
    editBusinessTaskDescriptionModalOpen,
    setEditBusinessTaskDescriptionModalOpen,
  ] = useState<boolean>(false);

  const statusColor = useMemo(() => {
    return getBusinessStatusColorByName(businessTask.status);
  }, [businessTask]);

  const statusName = useMemo(() => {
    const statusItem =
      businessTaskStatusTypes.find(
        (item) => item.name === businessTask.status,
      ) || businessTaskStatusTypes[0];
    return getBusinessStatusNameById(statusItem.id);
  }, [businessTask]);

  const handleSubmit = (values: any, afterSubmit?: () => void) => {
    if (businessTaskId) {
      updateBusinessTask(
        { taskId: businessTaskId, ...values },
        {
          onSuccess: () => {
            afterSubmit?.();
            refetchBusinessTask().catch((err) => message.error(err));
          },
        },
      );
    }
  };

  return (
    <div className={"business-task-details-wrapper"}>
      <div className={"business-task-info"}>
        <div>
          <div className={"company-name-wrapper"}>
            <div className={"company-name"}>{businessTask.company.name}</div>
            <SwapOutlined
              className="swap-icon"
              style={{ color: statusColor }}
            />
            <div style={{ color: statusColor }}>{statusName}</div>
          </div>

          <div className={"business-task-info-wrapper"}>
            <ContainerWithEdit onEditClick={() => setEditTitleModalOpen(true)}>
              <div className={"info-title"}>{businessTask.title}</div>
            </ContainerWithEdit>
            <ContainerWithEdit
              onEditClick={() => setEditBusinessTaskDetailsModalOpen(true)}
              align={"flex-start"}
            >
              <div className={"business-task-details"}>
                <InfoDetailItem
                  icon={<ClockCircleOutlined />}
                  name={"შექმნის თარიღი"}
                  value={moment(businessTask.creationDate).format(
                    "DD MMM YYYY, HH:mm",
                  )}
                />
                <InfoDetailItem
                  icon={<EnvironmentOutlined />}
                  name={"მისამართი"}
                  value={businessTask.address}
                />
                {businessTask.price !== null && (
                  <InfoDetailItem
                    icon={<DollarCircleOutlined />}
                    name={"ფასი"}
                    value={businessTask.price}
                  />
                )}
              </div>
            </ContainerWithEdit>

            <div className={"section"}>
              <ContainerWithEdit
                onEditClick={() =>
                  setEditBusinessTaskDescriptionModalOpen(true)
                }
              >
                <SectionTitle title={"დავალების აღწერა"} />
              </ContainerWithEdit>
              <div className={"section-content business-task-description"}>
                {businessTask.description}
              </div>
            </div>

            <div className={"section"}>
              <SectionTitle title={"კომპანიის ინფორმაცია"} />
              <div className={"section-content"}>
                <InfoDetailItem
                  icon={<BankOutlined />}
                  name={"სახელი"}
                  value={businessTask.company.name}
                />
                <InfoDetailItem
                  icon={<PhoneOutlined />}
                  name={"ნომერი"}
                  value={formatPhoneNumber(businessTask.company.number)}
                />
              </div>
            </div>

            <div className={"section"}>
              <SectionTitle title={"გამომგზავნის ინფორმაცია"} />
              <div className={"section-content"}>
                <InfoDetailItem
                  icon={<UserOutlined />}
                  name={"სახელი"}
                  value={`${businessTask.sender.name} ${businessTask.sender.surname}`}
                />
                <InfoDetailItem
                  icon={<MailOutlined />}
                  name={"ელ-ფოსტა"}
                  value={businessTask.sender.email}
                />
                <InfoDetailItem
                  icon={<PhoneOutlined />}
                  name={"ნომერი"}
                  value={formatPhoneNumber(businessTask.sender.phoneNumber)}
                />
              </div>
            </div>
          </div>
        </div>
        {actionButtons && (
          <div className={"action-buttons-wrapper"}>{actionButtons}</div>
        )}
      </div>
      <EditTitleModal
        modalOpen={editTitleModalOpen}
        setModalOpen={setEditTitleModalOpen}
        currentTitle={businessTask.title}
        onSubmit={handleSubmit}
        loading={isUpdateBusinessTaskLoading}
      />
      <EditBusinessTaskDetailsModal
        modalOpen={editBusinessTaskDetailsModalOpen}
        onClose={() => setEditBusinessTaskDetailsModalOpen(false)}
        isLoading={isUpdateBusinessTaskLoading}
        initialValues={{
          address: businessTask.address,
          price: businessTask.price,
        }}
        onSubmit={handleSubmit}
      />
      <EditBusinessTaskDescriptionModal
        modalOpen={editBusinessTaskDescriptionModalOpen}
        onClose={() => setEditBusinessTaskDescriptionModalOpen(false)}
        isLoading={isUpdateBusinessTaskLoading}
        initialValues={{
          description: businessTask.description,
        }}
        onSubmit={handleSubmit}
      />
    </div>
  );
};
