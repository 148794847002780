import React, { useEffect, useRef, useState } from "react";
import "./OTPModal.scss";
import { useTimer } from "react-timer-hook";
import { Button, message, Modal } from "antd";
import moment from "moment";
import { ReloadOutlined } from "@ant-design/icons";
import OtpInput from "react-otp-input-rc-17";

interface IOTPModal {
  modalOpen: boolean;
  setModalOpen: any;
  onSubmit: (code: string) => void;
  loading: boolean;
  hasError: boolean;
  setOtpError: any;
  phoneNumber: string;
  sendOtp: any; //@TODO: type UseMutateFunction
}

export const OTPModal: React.FC<IOTPModal> = ({
  modalOpen,
  setModalOpen,
  onSubmit,
  loading,
  hasError,
  setOtpError,
  phoneNumber,
  sendOtp,
}) => {
  const [otpValue, setOtpValue] = useState<string>("");
  const [canResend, setCanResend] = useState<boolean>(false);
  const ref = useRef<any>();

  const getExpiryTimestamp = () => {
    return moment()
      .set("seconds", moment().seconds() + 60)
      .toDate();
  };

  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp: getExpiryTimestamp(),
    onExpire: () => setCanResend(true),
  });

  useEffect(() => {
    if (ref.current) {
      ref.current.focusInput(0);
    }
  }, [ref.current]);

  useEffect(() => {
    if (!modalOpen) {
      setOtpError(false);
    }
  }, [modalOpen]);

  const restartTimer = () => {
    setCanResend(false);
    restart(getExpiryTimestamp());
  };

  return (
    <Modal
      open={modalOpen}
      wrapClassName={"otp-modal-wrap"}
      className={"otp-modal"}
      title={"შეიყვანეთ კოდი"}
      footer={null}
      onCancel={() => setModalOpen(false)}
      maskClosable
      destroyOnClose
      centered
    >
      <div className={"otp-modal-content-wrapper"}>
        <div
          className={"otp-code-sent-text"}
        >{`კოდი გამოიგზავნა ნომერზე ${phoneNumber}`}</div>
        <OtpInput
          ref={ref}
          containerStyle={"otp-input-wrapper"}
          focusStyle={"otp-input-focus"}
          inputStyle={"otp-input"}
          isInputNum
          shouldAutoFocus
          value={otpValue}
          onChange={(otp: string) => {
            setOtpValue(otp);
            setOtpError(false);
          }}
          numInputs={4}
          hasErrored={hasError}
          errorStyle={"otp-input-error"}
        />
        {hasError ? (
          <div className={"otp-input-error-text"}>{"კოდი არასწორია"}</div>
        ) : (
          <div className={"timer-wrapper"}>{`${minutes}:${seconds}`}</div>
        )}
        <div
          className={`send-new-code-wrapper ${!canResend ? "disabled" : ""}`}
          onClick={() => {
            if (canResend) {
              sendOtp(
                { phone: phoneNumber },
                {
                  onSuccess: () => {
                    restartTimer();
                  },
                  onError: () => {
                    message.error("დაფიქსირდა შეცდომა");
                  },
                },
              );
            }
          }}
        >
          <div className={"send-new-code-text"}>ახალი კოდის გამოგზავნა</div>
          <ReloadOutlined />
        </div>
        <Button
          className={"submit-button"}
          onClick={() => onSubmit(otpValue)}
          loading={loading}
        >
          დასტური
        </Button>
      </div>
    </Modal>
  );
};
