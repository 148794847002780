import React, { useMemo, useState } from "react";
import "./BusinessTasks.scss";
import { useGetBusinessTasks } from "../../../services/task";
import { Table } from "antd";
import { getDataSource } from "./table/datasource";
import { getColumns } from "./table/columns";
import { IOption } from "../../../services/generalTypes";
import { TaskStatusButton } from "../../../components/TaskStatusButton/TaskStatusButton";
import { useNavigate, useParams } from "react-router";
import {
  getBusinessStatusColorById,
  getBusinessStatusIcon,
  getBusinessStatusNameById,
} from "../../../utils/helpers";
import { useGetCompanies } from "../../../services/company";
import { CustomSelect } from "../../../components/CustomSelect/CustomSelect";
import { useBusinessTaskFilters } from "./hooks/useBusinessTaskFilters";
import { useBusinessTaskStatus } from "./hooks/useBusinessTaskStatus";
import { useTablePagination } from "../../../hooks/useTablePagination";
import { SearchInput } from "../../../components";
import { useDebouncedCallback } from "use-debounce";

export const BusinessTasks = () => {
  const { status: paramsStatus } = useParams();
  const navigate = useNavigate();
  const { pagination, resetPagination, resetCurrentPage } =
    useTablePagination(8);
  const { statusesArr, selectedTab, businessTaskStatuses } =
    useBusinessTaskStatus(paramsStatus);

  const { data: companies, isLoading: companiesLoading } = useGetCompanies();
  const [selectedCompany, setSelectedCompany] = useState<string | undefined>();
  const [searchText, setSearchText] = useState<string | undefined>();

  const {
    data: businessTasks,
    isLoading: businessTasksLoading,
    totalItemCount,
  } = useGetBusinessTasks({
    pageNumber: pagination.currentPage,
    pageSize: pagination.pageSize,
    statuses: businessTaskStatuses,
    companyId: selectedCompany,
    searchText,
  });

  const { filteredBusinessTasks } = useBusinessTaskFilters(businessTasks);

  const tableDataSource = useMemo(() => {
    if (filteredBusinessTasks) {
      return getDataSource(filteredBusinessTasks);
    }
    return [];
  }, [filteredBusinessTasks]);

  const tableColumns = useMemo(() => {
    return getColumns(companies, selectedTab);
  }, [companies, selectedTab]);

  const companyOptions = useMemo((): IOption[] => {
    if (companies) {
      return companies.map((company) => ({
        id: company.id,
        name: company.name,
      }));
    }
    return [];
  }, [companies]);

  const onBusinessTaskSearch = useDebouncedCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (pagination.currentPage !== 1) {
        resetCurrentPage();
      }
      setSearchText(e.target.value);
    },
    200,
  );

  return (
    <div className={"business-tasks-container"}>
      <div className={"statuses-wrapper"}>
        {statusesArr.map((item) => {
          const id = item.id;
          const selected = item.name === selectedTab;
          const statusColor = getBusinessStatusColorById(id);
          const name = getBusinessStatusNameById(id);
          const icon = getBusinessStatusIcon(id);
          return (
            <TaskStatusButton
              name={name}
              icon={icon}
              color={statusColor}
              statusItem={item}
              selected={selected}
              key={id}
              onClick={() => {
                resetPagination();
                navigate(`/business/${item.name}`);
              }}
            />
          );
        })}
      </div>
      <div className={"filters-container"}>
        <SearchInput onChange={onBusinessTaskSearch} />
        <CustomSelect
          value={selectedCompany}
          options={companyOptions}
          placeholder={"კომპანია"}
          loading={companiesLoading}
          onChange={(value: string) => {
            setSelectedCompany(value);
            resetPagination();
          }}
          onSelect={(value: string) => {
            if (value === selectedCompany) {
              setSelectedCompany(undefined);
            }
          }}
        />
      </div>
      <Table
        className={"business-tasks-table"}
        dataSource={tableDataSource}
        columns={tableColumns}
        loading={businessTasksLoading}
        onRow={(record) => {
          return {
            onClick: () => {
              navigate(`/business/${selectedTab}/${record.id}`);
            },
          };
        }}
        pagination={{
          ...pagination,
          total: totalItemCount || 1,
        }}
      />
    </div>
  );
};
