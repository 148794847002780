import React from "react";
import "./TaskItem.scss";
import { PhoneOutlined } from "@ant-design/icons";
import { getStatusColor, getStatusName } from "../../utils/helpers";
import { ITaskData } from "../../services/task";
import moment from "moment";

interface ITaskItem {
  task: ITaskData;
  withStatusName?: boolean;
  withPhoneNumber?: boolean;
  onClick?: () => void;
}

export const TaskItem: React.FC<ITaskItem> = ({
  task,
  withStatusName = false,
  withPhoneNumber = false,
  onClick,
}) => {
  return (
    <div
      className={"task-wrapper"}
      style={{ borderLeft: `5px solid ${getStatusColor(task?.taskStatus)}` }}
      onClick={onClick}
    >
      <div className={"task-info-wrapper"}>
        <div className={"task-main-info-wrapper"}>
          <div className={"task-main-info-left-wrapper"}>
            <div className={"client-name-wrapper"}>
              <div className={"client-name"}>{task?.clientName}</div>
              {" - "}
              {task?.createdDateTime && (
                <div>{moment(task.createdDateTime).format("DD/MM/YYYY")}</div>
              )}
              {task?.city && (
                <div className={"client-city"}>{`${task.city}${
                  task?.address ? "," : ""
                }`}</div>
              )}
              {task?.address && (
                <div className={"client-city"}>{task.address}</div>
              )}
              {task?.taskStatus && +task.taskStatus > 5 && (
                <div className={"task-specified-status"}>{`(${getStatusName(
                  task.taskStatus,
                )})`}</div>
              )}
            </div>
            {withPhoneNumber && (
              <>
                {" - "}
                <div className={"client-contact-wrapper"}>
                  <PhoneOutlined />
                  <div>{"Phone number temp"}</div>
                </div>
              </>
            )}
          </div>
          <div
            className={"task-main-info-right-wrapper"}
            style={{ color: getStatusColor(task.taskStatus) }}
          >
            {/*@TODO: update this*/}
            {withStatusName && (
              <div className={"task-status"}>{task?.taskStatus}</div>
            )}
          </div>
        </div>
        <div className={"task-title-wrapper"}>
          <div className={"task-title"}>{task?.title}</div>
        </div>
        <div className={"task-description-wrapper"}>
          <div className={"task-description"}>{task?.description}</div>
        </div>
      </div>
    </div>
  );
};
