import React, { useEffect, useState } from "react";
import "./AddTaskModal.scss";
import { Button, Form, message, Modal } from "antd";
import { useGetAdmin } from "../../../../../services/common";
import { useMutation } from "react-query";
import { createTask, useGetAllPresetTasks } from "../../../../../services/task";
import { FieldsStep } from "./Steps/FieldsStep";
import { CategoriesStep } from "./Steps/CategoriesStep";
import { ValueOf } from "../../../../../utils/type-helpers";
import TagManager from "react-gtm-module";

interface IAddTaskModal {
  modalOpen: boolean;
  setModalOpen: any;
  refetchTasks: () => Promise<any>;
}

const ADD_TASK_STEPS = {
  FIELDS: "fields",
  CATEGORIES: "categories",
} as const;

type AddTaskStep = ValueOf<typeof ADD_TASK_STEPS>;

export const AddTaskModal: React.FC<IAddTaskModal> = ({
  modalOpen,
  setModalOpen,
  refetchTasks,
}) => {
  const [form] = Form.useForm();
  const [step, setStep] = useState<AddTaskStep>(ADD_TASK_STEPS.FIELDS);
  const { admin } = useGetAdmin();
  const { data: allPresetTasks } = useGetAllPresetTasks();
  const { isLoading: createTaskLoading, mutate: mutateCreateTask } =
    useMutation(createTask);

  useEffect(() => {
    if (!modalOpen) {
      form.resetFields();
      setStep(ADD_TASK_STEPS.FIELDS);
    }
  }, [modalOpen]);

  const handleSubmit = () => {
    const values = form.getFieldsValue(true);
    mutateCreateTask(
      {
        ...values,
        Files: [],
      },
      {
        onSuccess: () => {
          const presetTask = allPresetTasks?.find(
            (preset) =>
              preset.presetId.toString() === values?.PresetTaskId?.toString(),
          );
          TagManager.dataLayer({
            dataLayer: {
              event: "preset_categories_chose_preset",
              item_name: presetTask?.name ?? "N/A",
              item_id: presetTask?.presetId ?? "N/A",
              preset_id: presetTask?.categoryId ?? "N/A",
            },
          });
          refetchTasks()
            .then(() => {
              setModalOpen(false);
            })
            .catch((err) => {
              message.error(err);
            });
        },
      },
    );
  };

  const renderFooterContent = () => {
    switch (step) {
      case ADD_TASK_STEPS.CATEGORIES:
        return (
          <>
            <Button onClick={() => setStep(ADD_TASK_STEPS.FIELDS)}>უკან</Button>
            <Button
              onClick={form.submit}
              loading={createTaskLoading}
              className={"gtm-add-task-button"}
            >
              დამატება
            </Button>
          </>
        );
      default:
        return (
          <Button
            onClick={() => {
              form.validateFields().then(() => {
                setStep(ADD_TASK_STEPS.CATEGORIES);
              });
            }}
          >
            შემდეგი
          </Button>
        );
    }
  };

  const renderStep = () => {
    switch (step) {
      case ADD_TASK_STEPS.CATEGORIES:
        return <CategoriesStep form={form} />;
      default:
        return <FieldsStep />;
    }
  };

  return (
    <Modal
      open={modalOpen}
      className={"add-task-modal"}
      title={"ახალი დავალების დამატება"}
      cancelText={"გაუქმება"}
      onCancel={() => setModalOpen(false)}
      maskClosable
      destroyOnClose
      centered
      footer={renderFooterContent()}
    >
      <Form
        form={form}
        className={"add-task-form"}
        onFinish={handleSubmit}
        initialValues={{
          SelectedCategoryId: 0,
        }}
      >
        {renderStep()}
      </Form>
    </Modal>
  );
};
