//POST https://wempler.ge/api/v1/company

import { GeneralResponseType } from "./generalTypes";
import { businessApiClient } from "./apiClient";
import { useMutation, useQuery, UseQueryOptions } from "react-query";

type CreateCompanyReq = {
  name: string;
  taxCode: string;
  legalAddress: string;
  actualAddress: string;
  adminUserId: string;
  cityId: 0;
  companyEmail: string;
};

export type CreateCompanyData = {
  companyId: string;
};

export type CreateCompanyResp = GeneralResponseType<CreateCompanyData>;

export const createCompany = (
  data: CreateCompanyReq,
): Promise<CreateCompanyData> =>
  businessApiClient.post("/company", data).then((resp) => resp.data);

export const useCreateCompany = (
  onSuccess?: (data: CreateCompanyData) => void,
  onError?: () => void,
) => {
  const { isLoading, mutate } = useMutation(createCompany, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};

//POST https://wempler.ge/api/v1/company/all

export type CompanyData = {
  id: string;
  taxCode: string;
  name: string;
  legalAddress: string;
  actualAddress: string;
  adminUserId: string;
  email: string;
  cityName: string;
};

type Companies = {
  data: CompanyData[];
  totalItemCount: number;
  page: number;
  offset: number;
  pageCount: number;
};

type CompaniesResp = {
  companiesList: Companies;
};

type CompaniesParams = {
  searchText?: string;
  pageNumber?: number;
  pageSize?: number;
};

export const getCompanies = (
  params?: CompaniesParams,
): Promise<CompaniesResp> =>
  businessApiClient.get(`/company/all`, { params }).then((resp) => resp.data);

export const useGetCompanies = (
  params?: CompaniesParams,
  options?: UseQueryOptions<CompaniesResp>,
) => {
  const pageNumber = params?.pageNumber;
  const pageSize = params?.pageSize;
  const searchText = params?.searchText;

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["company-all", pageNumber, pageSize, searchText],
    queryFn: () => getCompanies(params),
    ...options,
  });
  return {
    isLoading,
    data: data?.companiesList?.data,
    totalItemCount: data?.companiesList?.totalItemCount,
    page: data?.companiesList?.page,
    offset: data?.companiesList?.offset,
    pageCount: data?.companiesList?.pageCount,
    refetch,
  };
};
