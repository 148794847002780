import { ValueOf } from "../utils/type-helpers";

export type IOption = {
  id: string | number;
  name: string;
};

export type Status = {
  code: number;
  message: string;
};

export type GeneralResponseType<ResponseDataType> = {
  data: ResponseDataType;
  status: Status;
};

export const TASK_STATUS_TYPES = {
  CREATED: "created",
  ASSIGNED: "assigned",
  ACCEPTED: "accepted",
  IN_PROGRESS: "inProgress",
  DONE: "done",
  REJECTED: "rejected",
  REFUNDED: "refunded",
} as const;

export type TaskStatus = ValueOf<typeof TASK_STATUS_TYPES>;

export type TaskStatusItem = {
  id: number;
  name: TaskStatus;
};

export const taskStatusTypes: TaskStatusItem[] = Object.values(
  TASK_STATUS_TYPES,
).map((item: TaskStatus, index) => ({
  id: index + 1,
  name: item,
}));

export const taskStatusTypesSelect: IOption[] = [
  {
    id: 1,
    name: "შემოსული",
  },
  {
    id: 2,
    name: "შეთავაზებული",
  },
  {
    id: 3,
    name: "მიღებული",
  },
  {
    id: 4,
    name: "მიმდინარე",
  },
  {
    id: 5,
    name: "დასრულებული",
  },
  {
    id: 6,
    name: "გაუქმებული",
  },
  {
    id: 7,
    name: "უარყოფილი",
  },
  {
    id: 8,
    name: "ანაზღაურებული",
  },
];

export const taskSenderTypes: IOption[] = [
  {
    id: 1,
    name: "ვები",
  },
  {
    id: 2,
    name: "აპლიკაცია",
  },
  {
    id: 3,
    name: "სხვა",
  },
];

export const splitShares: IOption[] = [
  {
    id: 0.03,
    name: "3%",
  },
  {
    id: 0.05,
    name: "5%",
  },
  {
    id: 0.1,
    name: "10%",
  },
  {
    id: 0.15,
    name: "15%",
  },
  {
    id: 0.2,
    name: "20%",
  },
];

export const doerTypes: IOption[] = [
  {
    id: 1,
    name: "კერძო პირი",
  },
  {
    id: 2,
    name: "კომპანია",
  },
  {
    id: 3,
    name: "კერძო პირი კომპანიიდან",
  },
];

export const reviewStatusTypes: IOption[] = [
  {
    id: 1,
    name: "განხილვის პროცესში",
  },
  {
    id: 2,
    name: "უარყოფილი",
  },
  {
    id: 3,
    name: "დადასტურებული",
  },
];

export const BUSINESS_TASK_STATUS_TYPES = {
  INCOMING: "Incoming",
  ACCEPTED: "Accepted",
  COMPLETED: "Completed",
  REJECTED: "Rejected",
} as const;

export type BusinessTaskStatus = ValueOf<typeof BUSINESS_TASK_STATUS_TYPES>;

export type BusinessTaskStatusItem = {
  id: number;
  name: BusinessTaskStatus;
};

export const businessTaskStatusTypes: BusinessTaskStatusItem[] = Object.values(
  BUSINESS_TASK_STATUS_TYPES,
).map((item: BusinessTaskStatus, index) => ({
  id: index + 1,
  name: item,
}));

export const businessTaskStatusTypesSelect: IOption[] = [
  {
    id: 1,
    name: "შემომავალი",
  },
  {
    id: 2,
    name: "მიღებული",
  },
  {
    id: 3,
    name: "დასრულებული",
  },
  {
    id: 4,
    name: "გაუქმებული",
  },
];
