import React, { useState } from "react";
import { Button, message } from "antd";
import {
  BusinessTaskDetails,
  useCancelBusinessTask,
  useFinishBusinessTask,
} from "../../../../services/task";
import { BusinessTask } from "../BusinessTask/BusinessTask";
import { ConfirmationModal } from "../../Tasks/ActionModals";

type AcceptedBusinessTaskWrapperProps = {
  businessTask: BusinessTaskDetails;
  businessTaskId?: string;
  refetchBusinessTask: any;
};

export const AcceptedBusinessTaskWrapper: React.FC<
  AcceptedBusinessTaskWrapperProps
> = ({ businessTask, businessTaskId, refetchBusinessTask }) => {
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
  const [finishModalOpen, setFinishModalOpen] = useState<boolean>(false);

  const { mutate: cancelBusinessTask, isLoading: cancelBusinessTaskLoading } =
    useCancelBusinessTask();

  const { mutate: finishBusinessTask, isLoading: finishBusinessTaskLoading } =
    useFinishBusinessTask();

  const onCancelTaskSuccess = () => {
    setCancelModalOpen(true);
    refetchBusinessTask().catch((err: Error) => {
      message.error(err);
    });
  };

  const handleCancelTask = () => {
    cancelBusinessTask(businessTaskId, {
      onSuccess: onCancelTaskSuccess,
    });
  };

  const onFinishTaskSuccess = () => {
    setFinishModalOpen(false);
    refetchBusinessTask().catch((err: Error) => {
      message.error(err);
    });
  };

  const handleFinishTask = () => {
    finishBusinessTask(businessTaskId, {
      onSuccess: onFinishTaskSuccess,
      onError: () => {
        message.error("დაფიქსირდა შეცდომა");
      },
    });
  };

  return (
    <BusinessTask
      refetchBusinessTask={refetchBusinessTask}
      businessTask={businessTask}
      actionButtons={
        <>
          <Button
            className={"action-button red"}
            onClick={() => setCancelModalOpen(true)}
          >
            {"დავალების გაუქმება"}
          </Button>
          <ConfirmationModal
            modalOpen={cancelModalOpen}
            setModalOpen={setCancelModalOpen}
            title={"დავალების გაუქმება"}
            confirmationText={"ნამდვილად გსურთ დავალების გაუქმება?"}
            onSubmit={handleCancelTask}
            isLoading={cancelBusinessTaskLoading}
          />
          <Button
            className={"action-button green"}
            onClick={() => setFinishModalOpen(true)}
          >
            {"დავალების დასრულება"}
          </Button>
          <ConfirmationModal
            modalOpen={finishModalOpen}
            setModalOpen={setFinishModalOpen}
            title={"დავალების დასრულება"}
            confirmationText={"ნამდვილად გსურთ დავალების დასრულება?"}
            onSubmit={handleFinishTask}
            color={"#06D672"}
            isLoading={finishBusinessTaskLoading}
          />
        </>
      }
    />
  );
};
