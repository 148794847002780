import { CometChat } from "@cometchat-pro/chat";
import { message } from "antd";
import { useState } from "react";

export const useCometChatInit = () => {
  const cometChat_appID = process.env.REACT_APP_COMETCHAT_APP_ID;
  const cometChat_region = process.env.REACT_APP_COMETCHAT_REGION;

  const [isComeChatInitialized, setInitialized] = useState<boolean>(false);

  const cometInit = () => {
    const appSetting = new CometChat.AppSettingsBuilder()
      .subscribePresenceForAllUsers()
      .setRegion(cometChat_region)
      .build();

    CometChat.init(cometChat_appID, appSetting).then(
      (success) => {
        if (success) {
          console.log("Comet Chat Initialization completed successfully");
          setInitialized(true);
        } else {
          setInitialized(false);
        }
      },
      (error) => {
        message.error(error.message || "Comet init error");
      },
    );
  };

  return { cometInit, isComeChatInitialized };
};
