import React, { ReactNode } from "react";
import "./TaskStatusButton.scss";
import { Button } from "antd";
import {
  BusinessTaskStatusItem,
  TaskStatusItem,
} from "../../services/generalTypes";
import { ButtonProps } from "antd/es/button/button";

type TaskStatusButtonProps = ButtonProps & {
  statusItem: TaskStatusItem | BusinessTaskStatusItem;
  color: string;
  selected: boolean;
  className?: string;
  style?: React.CSSProperties;
  name: string;
  icon: ReactNode;
};

export const TaskStatusButton: React.FC<TaskStatusButtonProps> = ({
  name,
  icon,
  statusItem,
  selected,
  className,
  style,
  color,
  ...rest
}) => {
  const styles = selected
    ? {
        color: color,
        borderColor: color,
      }
    : {};

  return (
    <Button
      style={{ ...styles, ...style }}
      className={`task-status-button ${className ?? ""}`}
      {...rest}
    >
      {name}
      {icon}
    </Button>
  );
};
