import React from "react";
import "./InternalComment.scss";
import { CommentOutlined } from "@ant-design/icons";

interface IInternalComment {
  comment: string;
}

export const InternalComment: React.FC<IInternalComment> = ({ comment }) => {
  return (
    <div className={"internal-comment"}>
      <CommentOutlined />
      <div>
        {comment}
        {/*{" - "}*/}
        {/*<span className={"internal-comment-author"}>{`${comment.author} ${comment.date.format("YYYY-MM-DD")}`}</span>*/}
      </div>
    </div>
  );
};
