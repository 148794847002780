import { BusinessTaskData } from "../../../../services/task";
import { BusinessTaskStatus } from "../../../../services/generalTypes";

export type DataSource = {
  id: string;
  key: string;
  title: string;
  description: string;
  address: string;
  companyId: string;
  price: string | null;
  status: BusinessTaskStatus;
};

export const getDataSource = (
  businessTasks: BusinessTaskData[],
): DataSource[] => {
  return businessTasks.map((task) => {
    return {
      id: task.id,
      key: task.id,
      title: task.title,
      description: task.description,
      address: task.address,
      companyId: task.companyId,
      price: task.price,
      status: task.status,
    };
  });
};
