import { useQuery } from "react-query";
import { apiClient, businessApiClient } from "./apiClient";
import { GeneralResponseType, IOption, Status } from "./generalTypes";

type GetCitiesResp = GeneralResponseType<IOption[]>;

export const getCities = (): Promise<GetCitiesResp> =>
  apiClient.get("/catalog/cities").then((resp) => resp.data);

export type UseGetCitiesResp = {
  isLoading: boolean;
  cities?: IOption[];
  status?: Status;
};

export const useGetCities = (): UseGetCitiesResp => {
  const { data, isLoading } = useQuery(["catalog-cities"], () => getCities());
  return {
    isLoading,
    cities: data?.data,
    status: data?.status,
  };
};

export interface ICancellationReason {
  id: number;
  name: string;
  isTerminating: boolean;
}

type GetCancellationReasonsResp = GeneralResponseType<ICancellationReason[]>;

export const getCancellationReasons = (): Promise<GetCancellationReasonsResp> =>
  apiClient.get("/catalog/cancellationReasons").then((resp) => resp.data);

export const useGetCancellationReasons = (enabled = true) => {
  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ["catalog-cancellationReasons"],
    queryFn: () => getCancellationReasons(),
    enabled,
  });
  return {
    isLoading,
    cancellationReasons: data?.data,
    status: data?.status,
    refetch,
    isRefetching,
  };
};

type GetSkillsResp = GeneralResponseType<IOption[]>;

export const getSkills = (): Promise<GetSkillsResp> =>
  apiClient.get("/catalog/skills").then((resp) => resp.data);

export type UseGetSkillsResp = {
  isLoading: boolean;
  skills?: IOption[];
  status?: Status;
  refetch: any;
  isRefetching: boolean;
};

export const useGetSkills = (): UseGetSkillsResp => {
  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ["catalog-skills"],
    queryFn: () => getSkills(),
  });
  return {
    isLoading,
    skills: data?.data,
    status: data?.status,
    refetch,
    isRefetching,
  };
};

//BUSINESS

type GetBusinessSkillsResp = {
  skills: IOption[];
};

export const getBusinessSkills = (): Promise<GetBusinessSkillsResp> =>
  businessApiClient.get("/catalog/skills").then((resp) => resp.data);

export type UseGetBusinessSkillsResp = {
  isLoading: boolean;
  skills?: IOption[];
  refetch: any;
  isRefetching: boolean;
};

export const useGetBusinessSkills = (): UseGetBusinessSkillsResp => {
  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ["business-catalog-skills"],
    queryFn: () => getBusinessSkills(),
  });
  return {
    isLoading,
    skills: data?.skills,
    refetch,
    isRefetching,
  };
};

type BusinessCitiesResp = {
  cities: IOption[];
};

export const getBusinessCities = (): Promise<BusinessCitiesResp> =>
  businessApiClient.get("/catalog/cities").then((resp) => resp.data);

export type UseGetBusinessCitiesResp = {
  isLoading: boolean;
  cities?: IOption[];
};

export const useGetBusinessCities = (): UseGetBusinessCitiesResp => {
  const { data, isLoading } = useQuery(["business-catalog-cities"], () =>
    getBusinessCities(),
  );
  return {
    isLoading,
    cities: data?.cities,
  };
};
