import React, { useState } from "react";
import { Task } from "../Task/Task";
import { Button, message } from "antd";
import { RejectTaskModal } from "../ActionModals/RejectTaskModal/RejectTaskModal";
import { ConfirmationModal } from "../ActionModals";
import { useMutation } from "react-query";
import { ITaskDetails, startTask } from "../../../../services/task";

interface IAcceptedTaskWrapper {
  task: ITaskDetails;
  refetchTask: any;
}

export const AcceptedTaskWrapper: React.FC<IAcceptedTaskWrapper> = ({
  task,
  refetchTask,
}) => {
  const [rejectModalOpen, setRejectModalOpen] = useState<boolean>(false);
  const [startModalOpen, setStartModalOpen] = useState<boolean>(false);

  const { isLoading: startTaskLoading, mutate: mutateStartTask } = useMutation(
    startTask,
    {
      onSuccess: () => {
        setStartModalOpen(false);
        refetchTask().catch((err: any) => message.error(err));
      },
      onError: (err) => {
        message.error(err);
      },
    },
  );

  return (
    <Task
      task={task}
      actionButtons={
        <>
          <Button
            className={"action-button red"}
            onClick={() => setRejectModalOpen(true)}
          >
            {"დავალების გაუქმება"}
          </Button>
          <RejectTaskModal
            modalOpen={rejectModalOpen}
            setModalOpen={setRejectModalOpen}
            title={"დავალების გაუქმება"}
            confirmationText={"ნამდვილად გსურთ დავალების გაუქმება?"}
            task={task}
          />
          <Button
            className={"action-button green"}
            onClick={() => setStartModalOpen(true)}
          >
            {"დავალების დაწყება"}
          </Button>
          <ConfirmationModal
            modalOpen={startModalOpen}
            setModalOpen={setStartModalOpen}
            title={"დავალების დაწყება"}
            confirmationText={"ნამდვილად გსურთ დავალების დაწყება?"}
            onSubmit={() => {
              mutateStartTask({
                taskId: task.id,
              });
            }}
            color={"#06D672"}
            isLoading={startTaskLoading}
          />
        </>
      }
    />
  );
};
