import React, { useEffect } from "react";
import "./EditSkillsModal.scss";
import { Col, Form, Modal, Row } from "antd";
import { MultipleSelect } from "../../../../../components";
import { useGetSkills } from "../../../../../services/catalog";
import { formValidation } from "../../../../../utils/formValidation";
import { IOption } from "../../../../../services/generalTypes";

interface IEditSkillsModal {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: (values: any, afterSubmit?: () => void) => void;
  loading?: boolean;
  currentSkills: IOption[];
}

export const EditSkillsModal: React.FC<IEditSkillsModal> = ({
  modalOpen,
  setModalOpen,
  onSubmit,
  loading,
  currentSkills,
}) => {
  const [form] = Form.useForm();
  const { isLoading: skillsLoading, skills } = useGetSkills();

  useEffect(() => {
    if (!modalOpen) {
      form.resetFields();
    }
  }, [form, modalOpen]);

  const handleSubmit = (values: any) => {
    onSubmit?.(values, () => setModalOpen(false));
  };

  useEffect(() => {
    if (modalOpen) {
      form.setFieldsValue({
        skills: currentSkills.map((skill) => skill.id),
      });
    }
  }, [currentSkills, modalOpen]);

  return (
    <Modal
      open={modalOpen}
      className={"edit-skills-modal"}
      title={"სახელის რედაქტირება"}
      onOk={form.submit}
      okText={"დასტური"}
      cancelText={"დახურვა"}
      onCancel={() => setModalOpen(false)}
      maskClosable
      destroyOnClose
      okButtonProps={{
        loading: loading,
      }}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Row>
          <Col span={24}>
            <Form.Item name={"skills"} rules={formValidation.required}>
              <MultipleSelect
                placeholder="კატეგორია"
                options={skills}
                loading={skillsLoading}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
