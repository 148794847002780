import React from "react";
import { CompanyData } from "../../../../services/company";
import { TableProps, Tag } from "antd";
import { DataSource } from "./datasource";
import {
  BUSINESS_TASK_STATUS_TYPES,
  BusinessTaskStatus,
  businessTaskStatusTypes,
} from "../../../../services/generalTypes";
import {
  getBusinessStatusColorById,
  getBusinessStatusNameById,
} from "../../../../utils/helpers";
import { StatusTag } from "../../../../components";

export const getColumns = (
  companies: CompanyData[] | undefined,
  selectedTab: BusinessTaskStatus,
): TableProps<DataSource>["columns"] => [
  {
    title: "სახელი",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "აღწერა",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "მისამართი",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "კომპანია",
    dataIndex: "companyId",
    key: "companyId",
    render: (_: any, { companyId }: DataSource) => {
      const companyName = companies?.find(
        (item) => item.id === companyId,
      )?.name;
      return <div>{companyName ?? "-"}</div>;
    },
  },
  {
    title: "ფასი",
    dataIndex: "price",
    key: "price",
    hidden: selectedTab === BUSINESS_TASK_STATUS_TYPES.INCOMING,
    render: (_: any, { price }: DataSource) => {
      return <div>{price ? `${price}₾` : "-"}</div>;
    },
  },
  {
    title: "სტატუსი",
    dataIndex: "status",
    key: "status",
    hidden: selectedTab !== BUSINESS_TASK_STATUS_TYPES.COMPLETED,
    render: (_: any, { status }: DataSource) => {
      const statusId = businessTaskStatusTypes.find(
        (statusType) => statusType.name === status,
      )?.id;

      if (statusId) {
        return (
          <StatusTag
            color={getBusinessStatusColorById(statusId)}
            text={getBusinessStatusNameById(statusId)}
          />
        );
      }
    },
  },
];
