import { InputProps } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React from "react";
import { CustomInput } from "../CustomInput/CustomInput";

export const SearchInput: React.FC<InputProps> = ({ ...rest }) => {
  return (
    <CustomInput
      placeholder={"ძებნა..."}
      suffix={<SearchOutlined />}
      {...rest}
    />
  );
};
