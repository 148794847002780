import React from "react";
import "./ConfirmationModal.scss";
import { Modal } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

interface ICancelTaskModal {
  modalOpen: boolean;
  //eslint-disable-next-line
  setModalOpen: any;
  title: string;
  confirmationText: string;
  onSubmit: () => void;
  color?: string;
  icon?: JSX.Element;
  isLoading?: boolean;
}

export const ConfirmationModal: React.FC<ICancelTaskModal> = ({
  modalOpen,
  setModalOpen,
  title,
  confirmationText,
  onSubmit,
  color,
  icon,
  isLoading,
}) => {
  return (
    <Modal
      open={modalOpen}
      className={"cancel-task-modal"}
      title={title}
      onOk={onSubmit}
      okText={"დიახ"}
      cancelText={"არა"}
      onCancel={() => setModalOpen(false)}
      maskClosable
      destroyOnClose
      okButtonProps={{
        style: {
          backgroundColor: color || "#FF3100",
          borderColor: color || "#FF3100",
        },
        loading: isLoading,
      }}
    >
      <div className={"cancel-task-modal-content-wrapper"}>
        <div
          className={"modal-icon-wrapper"}
          style={{ color: color || "#FF3100" }}
        >
          {icon || <InfoCircleOutlined />}
        </div>
        <div className={"modal-confirmation-text"}>{confirmationText}</div>
      </div>
    </Modal>
  );
};
