import React from "react";
import "./AddButton.scss";
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { ButtonProps } from "antd/lib";

export const AddButton: React.FC<ButtonProps> = ({ className, ...rest }) => {
  return (
    <Button className={`custom-add-button ${className ?? ""}`} {...rest}>
      <PlusOutlined />
    </Button>
  );
};
