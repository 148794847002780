import { apiClient, businessApiClient } from "./apiClient";
import { getToken, removeToken } from "../utils/helpers";
import { GeneralResponseType } from "./generalTypes";
import { useMutation } from "react-query";

apiClient.interceptors.request.use(
  (config) => {
    const token = getToken();

    if (token && config?.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.log("request error", error);
    return Promise.reject(error);
  },
);

businessApiClient.interceptors.request.use(
  (config) => {
    const token = getToken();

    if (token && config?.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.log("request error", error);
    return Promise.reject(error);
  },
);

apiClient.interceptors.response.use(
  (resp) => resp,
  (err) => {
    console.log(err);
    if (err.response.status.toString() === "401") {
      removeToken();
    }
    //remove token from localstorage
    //redirect to login
  },
);

businessApiClient.interceptors.response.use(
  (resp) => resp,
  (err) => {
    console.log(err);
    if (err.response.status.toString() === "401") {
      removeToken();
    }
    //remove token from localstorage
    //redirect to login
  },
);

// POST https://wempler.ge/api/v1/otp/gosmsOtpRequest
export interface ISendOtpReq {
  phoneNumber: string;
}

export type HashData = {
  hash: string | null;
};

type SendOtpResp = GeneralResponseType<HashData>;

export const sendOtpCode = (data: ISendOtpReq): Promise<SendOtpResp> =>
  apiClient.post("/otp/gosmsOtpRequest", data).then((resp) => {
    return resp.data;
  });

// POST https://wempler.ge/api/v1/auth/gosmsLogin
export interface IOtpVerifyCodeReq {
  phoneNumber: string;
  hash: string;
  code: string;
}

type TokenData = {
  accessToken: string;
  refreshToken: string;
};

type VerifyOtpCodeResp = GeneralResponseType<TokenData>;

export const verifyOtpCode = (
  data: IOtpVerifyCodeReq,
): Promise<VerifyOtpCodeResp> =>
  apiClient.post("/auth/gosmsLogin", data).then((resp) => resp.data);

//POST https://wempler.ge/api/v1/auth/check-email

type CheckEmailReq = {
  email: string;
};

export type CheckEmailData = {
  userId: string | null;
};

export type CheckEmailResp = GeneralResponseType<CheckEmailData>;

export const checkEmail = (data: CheckEmailReq): Promise<CheckEmailData> =>
  businessApiClient.post("/auth/check-email", data).then((resp) => resp.data);

export const useCheckEmail = (
  onSuccess?: (data: CheckEmailData) => void,
  onError?: () => void,
) => {
  const { isLoading, mutate } = useMutation(checkEmail, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};

//POST https://wempler.ge/api/v1/auth/register-with-link

type RegisterWithLinkReq = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
};

export type UserId = string | null;

export type RegisterWithLinkData = {
  message: string;
  userId: UserId;
};

export type RegisterWithLinkResp = GeneralResponseType<RegisterWithLinkData>;

export const registerWithLink = (
  data: RegisterWithLinkReq,
): Promise<RegisterWithLinkData> =>
  businessApiClient
    .post("/auth/register-with-link", data)
    .then((resp) => resp.data);

export const useRegisterWithLink = (
  onSuccess?: (data: CheckEmailData) => void,
  onError?: () => void,
) => {
  const { isLoading, mutate } = useMutation(registerWithLink, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};
