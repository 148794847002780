import { apiClient, businessApiClient } from "./apiClient";
import { useQuery } from "react-query";
import { GeneralResponseType, IOption, Status } from "./generalTypes";
import qs from "qs";

export interface IDoer {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  avatar: string;
  avaibility: string;
  city: IOption;
  skills: IOption[];
  isTrusted: boolean;
  doerType: number;
  companyName: string;
  companyImageUrl: string;
}

export interface IDoers {
  data: IDoer[];
  totalItemCount: number;
  page: number;
  offset: number;
  pageCount: number;
}

type GetDoersResp = GeneralResponseType<IDoers>;

export const getDoers = (data: any): Promise<GetDoersResp> => {
  const [_key, { currentPage, pageSize, skillIds, cityId, searchText }] =
    data.queryKey;
  return apiClient
    .post("/doer/filter", {
      pageNumber: currentPage,
      pageSize,
      skillIds,
      cityId,
      searchText,
    })
    .then((resp) => resp.data);
};

export type UseGetDoersResp = {
  isLoading: boolean;
  doers?: IDoers;
  status?: Status;
  refetch: any;
  isRefetching: boolean;
};

export const useGetDoers = (
  currentPage: number,
  pageSize: number,
  skillIds: Array<string | number>,
  cityId: number,
  searchText?: string,
  enabledFetch = true,
): UseGetDoersResp => {
  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: [
      "doer-filter",
      { currentPage, pageSize, skillIds, cityId, searchText },
    ],
    queryFn: getDoers,
    enabled: enabledFetch,
  });
  return {
    isLoading,
    doers: data?.data,
    status: data?.status,
    refetch,
    isRefetching,
  };
};

//BUSINESS

type GetBusinessDoersResp = {
  doerList: IDoers;
};

export const getBusinessDoers = (data: any): Promise<GetBusinessDoersResp> => {
  const [_key, { currentPage, pageSize, skills, cityId, searchText }] =
    data.queryKey;
  return businessApiClient
    .get("/doer/filter", {
      params: {
        pageNumber: currentPage,
        pageSize,
        skills,
        cityId,
        searchText,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    })
    .then((resp) => resp.data);
};

export type UseGetBusinessDoersResp = {
  isLoading: boolean;
  doers?: IDoers;
  refetch: any;
  isRefetching: boolean;
};

export const useGetBusinessDoers = (
  currentPage: number,
  pageSize: number,
  skills: Array<string | number>,
  cityId: number,
  searchText?: string,
  enabledFetch = true,
): UseGetBusinessDoersResp => {
  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: [
      "business-doer-filter",
      { currentPage, pageSize, skills, cityId, searchText },
    ],
    queryFn: getBusinessDoers,
    enabled: enabledFetch,
  });
  return {
    isLoading,
    doers: data?.doerList,
    refetch,
    isRefetching,
  };
};

export interface IReview {
  id: string;
  rating: number;
  comment: string;
  commenterName: string;
  commenterAvatar: string;
}

export interface IDoerDetails {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  avatar: string;
  skills: IOption[];
  city: IOption;
  aboutMe: string;
  avaibility: string;
  //hourly rate?
  //active tasks?
  //completed tasks?
  iban: string;
  notes: string;
  reviews: IReview[];
  status: number; //?????
  isTrusted: true;
  doerType: number;
  companyName: string;
  companyImageUrl: string;
}

type GetDoerDetailsResp = GeneralResponseType<IDoerDetails>;

export const getDoerDetails = (
  data: any,
): Promise<GetDoerDetailsResp> | null => {
  const [_key, { id }] = data.queryKey;
  if (id) {
    return apiClient.get(`/doer/${id}`).then((resp) => resp.data);
  }
  return null;
};

export type UseGetDoerDetailsResp = {
  isLoading: boolean;
  doer?: IDoerDetails;
  status?: Status;
  refetch: any;
};

export const useGetDoerDetails = (
  id?: number | string,
): UseGetDoerDetailsResp => {
  const { data, isLoading, refetch } = useQuery(
    ["doer-id", { id }],
    getDoerDetails,
  );
  return {
    isLoading,
    doer: data?.data,
    status: data?.status,
    refetch,
  };
};

//BUSINESS

export const getBusinessDoerDetails = (
  data: any,
): Promise<IDoerDetails> | null => {
  const [_key, { id }] = data.queryKey;
  if (id) {
    return businessApiClient.get(`/doer/${id}`).then((resp) => resp.data);
  }
  return null;
};

export type UseGetBusinessDoerDetailsResp = {
  isLoading: boolean;
  doer?: IDoerDetails | null;
  refetch: any;
};

export const useGetBusinessDoerDetails = (
  id?: number | string,
): UseGetBusinessDoerDetailsResp => {
  const { data, isLoading, refetch } = useQuery(
    ["business-doer-id", { id }],
    getBusinessDoerDetails,
  );
  return {
    isLoading,
    doer: data,
    refetch,
  };
};

interface ICreateDoerReq {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  avatar: string;
  cityId: number;
  aboutMe: string;
  availability: string;
  iban: string;
  note: string;
  skillIds: number[];
  isTrusted: true;
  doerType: number;
  companyName: string;
  companyImageUrl: string;
}

export const createDoer = (data: ICreateDoerReq): Promise<string> =>
  apiClient.post("/doer/create", data).then((resp) => resp.data);

export interface IUpdateDoer {
  doerId: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  avatar?: string;
  skills?: number[];
  cityId?: number;
  aboutMe?: string;
  avaibility?: string;
  iban?: string;
  note?: string;
  isTrusted?: true;
  doerType?: number;
  companyName?: string;
  companyImageUrl?: string;
}

export const updateDoer = (data: IUpdateDoer): Promise<void> =>
  apiClient.post("/doer/update", data).then((resp) => resp.data);
