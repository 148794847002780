import { Button, Col, Form, Input, Row } from "antd";
import React from "react";
import { formValidation } from "../../../../../utils/formValidation";
import { useCheckEmail, UserId } from "../../../../../services/auth";
import { CustomInput } from "../../../../../components";

type EmailInputStepProps = {
  onEmailCheckSuccess: (userId: UserId) => void;
};

export const EmailInputStep: React.FC<EmailInputStepProps> = ({
  onEmailCheckSuccess,
}) => {
  const form = Form.useFormInstance();
  const { mutate: checkEmail, isLoading: checkEmailLoading } = useCheckEmail();

  const onNext = () => {
    form
      .validateFields()
      .then(() => {
        const email = form.getFieldValue("email");
        if (email) {
          checkEmail(
            { email },
            {
              onSuccess: (resp) => onEmailCheckSuccess(resp.userId),
            },
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className={"add-company-subtitle"}>
        შეიყვანეთ ინფორმაცია მომხმარებლის შესახებ
      </div>
      <Row>
        <Col span={24}>
          <Form.Item name={"email"} rules={formValidation.required}>
            <CustomInput placeholder={"ელ-ფოსტა"} />
          </Form.Item>
        </Col>
      </Row>
      <div className={"buttons-wrapper"}>
        <Button
          htmlType={"submit"}
          className={"next-button"}
          onClick={onNext}
          loading={checkEmailLoading}
        >
          შემდეგი
        </Button>
      </div>
    </>
  );
};
