import React, { useEffect } from "react";
import "./EditStatusModal.scss";
import { Col, Form, Modal, Row } from "antd";
import { taskStatusTypesSelect } from "../../../../../services/generalTypes";
import { CustomSelect } from "../../../../../components/CustomSelect/CustomSelect";
import { formValidation } from "../../../../../utils/formValidation";

interface IEditTaskModal {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedStatus: number;
  onSubmit?: (values: any, afterSubmit?: () => void) => void;
  loading?: boolean;
}

export const EditStatusModal: React.FC<IEditTaskModal> = ({
  modalOpen,
  setModalOpen,
  selectedStatus,
  onSubmit,
  loading,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (!modalOpen) {
      form.resetFields();
    }
  }, [form, modalOpen]);

  useEffect(() => {
    form.setFieldsValue({
      status: selectedStatus,
    });
  }, [selectedStatus, modalOpen]);

  const handleSubmit = (values: any) => {
    onSubmit?.(values, () => setModalOpen(false));
  };

  return (
    <Modal
      open={modalOpen}
      className={"edit-status-modal"}
      title={"სტატუსის რედაქტირება"}
      onOk={form.submit}
      okText={"დასტური"}
      cancelText={"დახურვა"}
      onCancel={() => setModalOpen(false)}
      maskClosable
      destroyOnClose
      okButtonProps={{
        loading: loading,
      }}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Row>
          <Col span={24}>
            <Form.Item name={"status"} rules={formValidation.required}>
              <CustomSelect
                options={taskStatusTypesSelect}
                placeholder={"სტატუსი"}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
