import React, { useEffect } from "react";
import "./Layout.scss";
import { Layout as AntdLayout } from "antd";
import { SideBar } from "./SideBar/SideBar";
import { Header } from "./Header/Header";
import { Content as AntdContent } from "antd/es/layout/layout";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router";
import { useDesktopNotifications } from "../../hooks/useDesktopNotifications";

export const Layout = () => {
  const { pathname } = useLocation();
  const { connectToHub } = useDesktopNotifications();

  //INITIALIZE DESKTOP NOTIFICATIONS
  useEffect(() => {
    connectToHub();
  }, []);

  return (
    <AntdLayout className="layout">
      <SideBar />
      <AntdLayout>
        <Header />
        <AntdContent
          className={`content ${
            !pathname.includes("chat") ? "with-padding" : ""
          }`}
        >
          <Outlet />
        </AntdContent>
      </AntdLayout>
    </AntdLayout>
  );
};
