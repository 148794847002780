import React from "react";
import "./MultipleSelect.scss";
import { Select } from "antd";

interface IMultipleSelect {
  className?: string;
  // eslint-disable-next-line
  [prop: string]: any;
}

export const MultipleSelect: React.FC<IMultipleSelect> = ({
  className,
  ...rest
}) => {
  return (
    <Select
      className={`multiple-select ${className || ""}`}
      mode="tags"
      popupClassName={"multiple-select-dropdown"}
      size={"large"}
      fieldNames={{ label: "name", value: "id" }}
      maxTagCount={"responsive"}
      {...rest}
    />
  );
};
