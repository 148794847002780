import React from "react";
import "./StatusTag.scss";

type StatusTagProps = {
  color: string;
  text: string;
};

export const StatusTag: React.FC<StatusTagProps> = ({ color, text }) => {
  const rgbaColor = hexToRgba(color, 0.1);
  return (
    <div
      className={"status-tag-container"}
      style={{
        backgroundColor: rgbaColor,
        border: `1px solid ${color}`,
        color,
      }}
    >
      {text}
    </div>
  );
};

const hexToRgba = (hex: string, alpha: number) => {
  // Remove the "#" if present
  hex = hex.replace(/^#/, "");

  // Parse the r, g, b values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Return the rgba string with the specified alpha value
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
