import { IAction } from "../index";
import * as types from "./AuthActionTypes";
import { ISAuthInitialState } from "./index";

const initialState: ISAuthInitialState = {
  isLoggedIn: false,
};

export const AuthReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case types.AUTHORIZE_USER:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    default:
      return { ...state };
  }
};
