import React, { useEffect, useMemo, useState } from "react";
import "./SideBar.scss";
import { Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import { Logo } from "../../../assets/svg";
import {
  MessageOutlined,
  ScheduleOutlined,
  StarOutlined,
  TeamOutlined,
  BankOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router";
import { ValueOf } from "../../../utils/type-helpers";

const SIDEBAR_KEYS = {
  TASKS: "tasks",
  DOERS: "doers",
  REVIEWS: "reviews",
  CHAT: "chat",
  COMPANIES: "companies",
  BUSINESS: "business",
} as const;

type SidebarKey = ValueOf<typeof SIDEBAR_KEYS>;

type SidebarItem = {
  key: SidebarKey;
  icon: JSX.Element;
  label: string;
};

const sidebarItemsList: SidebarItem[] = [
  {
    key: SIDEBAR_KEYS.TASKS,
    icon: <ScheduleOutlined />,
    label: "დავალებები",
  },
  {
    key: SIDEBAR_KEYS.DOERS,
    icon: <TeamOutlined />,
    label: "შემსრულებლები",
  },
  {
    key: SIDEBAR_KEYS.REVIEWS,
    icon: <StarOutlined />,
    label: "შეფასებები",
  },
  {
    key: SIDEBAR_KEYS.CHAT,
    icon: <MessageOutlined />,
    label: "ჩატი",
  },
  {
    key: SIDEBAR_KEYS.COMPANIES,
    icon: <BankOutlined />,
    label: "კომპანიები",
  },
  {
    key: SIDEBAR_KEYS.BUSINESS,
    icon: <SolutionOutlined />,
    label: "ბიზნესი",
  },
];

export const SideBar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [selectedKeys, setSelectedKeys] = useState<Array<SidebarKey>>([
    SIDEBAR_KEYS.TASKS,
  ]);

  useEffect(() => {
    const activeKey = Object.values(SIDEBAR_KEYS).find((item: SidebarKey) =>
      pathname.includes(item),
    );
    if (activeKey) {
      setSelectedKeys([activeKey]);
    }
  }, [pathname]);

  const onMenuItemClick = (pathName?: string) => {
    navigate(`/${pathName}`);
  };

  const sidebarItems = useMemo(() => {
    return sidebarItemsList.map((item) => ({
      ...item,
      onClick: () => onMenuItemClick(item.key),
    }));
  }, []);

  return (
    <Sider collapsible className={"sidebar"} breakpoint={"md"}>
      <div className={"logo-container"}>
        <Logo />
      </div>
      <Menu
        className={"sidebar-menu"}
        theme="dark"
        mode="inline"
        defaultSelectedKeys={selectedKeys}
        selectedKeys={selectedKeys}
        items={sidebarItems}
      />
    </Sider>
  );
};
