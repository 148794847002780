import { Select } from "antd";
import React from "react";
import "./CustomSelect.scss";
import { IOption } from "../../services/generalTypes";

type Props = {
  options?: IOption[];
  className?: string;
  [prop: string]: any;
};

const { Option } = Select;

export const CustomSelect: React.FC<Props> = ({
  options,
  className,
  ...rest
}) => {
  return (
    <Select
      className={`custom-select ${className || ""}`}
      popupClassName={"custom-select-dropdown"}
      {...rest}
    >
      {options?.map((item) => (
        <Option key={item.id} value={item.id}>
          {item.name}
        </Option>
      ))}
    </Select>
  );
};
