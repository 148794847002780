import React, { ReactNode } from "react";
import "./InfoDetailItem.scss";

type InfoDetailItemProps = {
  icon: ReactNode;
  name: string;
  value: string | number | ReactNode;
};

export const InfoDetailItem: React.FC<InfoDetailItemProps> = ({
  icon,
  name,
  value,
}) => {
  return (
    <div className={"info-detail-item-wrapper"}>
      {icon}
      <div>{`${name}:`}</div>
      <div>{value}</div>
    </div>
  );
};
