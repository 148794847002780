import Translator from "../../../resources/localization/translator";

export const LocalizedString = (language) => {
  return {
    SELECT_VIDEO_SOURCE: Translator.translate("SELECT_VIDEO_SOURCE", language),
    SELECT_INPUT_AUDIO_SOURCE: Translator.translate(
      "SELECT_INPUT_AUDIO_SOURCE",
      language,
    ),
    SELECT_OUTPUT_AUDIO_SOURCE: Translator.translate(
      "SELECT_OUTPUT_AUDIO_SOURCE",
      language,
    ),
    SELECT_MODE: Translator.translate("SELECT_MODE", language),
    CALL_RECORDING_STARTED_MSG: Translator.translate(
      "CALL_RECORDING_STARTED_MSG",
      language,
    ),
    USER_JOINED_CALL_MSG: Translator.translate(
      "USER_JOINED_CALL_MSG",
      language,
    ),
    CALL_RECORDING_ENDED_MSG: Translator.translate(
      "CALL_RECORDING_ENDED_MSG",
      language,
    ),
    USER_LEFT_CALL_MSG: Translator.translate("USER_LEFT_CALL_MSG", language),
    CAMERA_ERROR_MSG: Translator.translate("CAMERA_ERROR_MSG", language),
    MIC_ERROR_MSG: Translator.translate("MIC_ERROR_MSG", language),
    CAMERA_MIC_ERROR_MSG: Translator.translate(
      "CAMERA_MIC_ERROR_MSG",
      language,
    ),
    RECORDING_TEXT: Translator.translate("RECORDING_TEXT", language),
    TRYING_TO_START_RECORDING_TEXT: Translator.translate(
      "TRYING_TO_START_RECORDING_TEXT",
      language,
    ),
    JOINING_TEXT: Translator.translate("JOINING_TEXT", language),
    UN_MUTE_AUDIO_TEXT: Translator.translate("UN_MUTE_AUDIO_TEXT", language),
    MUTE_AUDIO_TEXT: Translator.translate("MUTE_AUDIO_TEXT", language),
    END_CALL_TEXT: Translator.translate("END_CALL_TEXT", language),
    START_RECORDING_TEXT: Translator.translate(
      "START_RECORDING_TEXT",
      language,
    ),
    STOP_RECORDING_TEXT: Translator.translate("STOP_RECORDING_TEXT", language),
    START_SCREEN_SHARE_TEXT: Translator.translate(
      "START_SCREEN_SHARE_TEXT",
      language,
    ),
    STOP_SCREEN_SHARE_TEXT: Translator.translate(
      "STOP_SCREEN_SHARE_TEXT",
      language,
    ),
    MUTE_VIDEO_TEXT: Translator.translate("MUTE_VIDEO_TEXT", language),
    UN_MUTE_VIDEO_TEXT: Translator.translate("UN_MUTE_VIDEO_TEXT", language),
    SELECT_LAYOUT_HEADER: Translator.translate(
      "SELECT_LAYOUT_HEADER",
      language,
    ),
    NO_OF_USER_IN_TILE_TEXT: Translator.translate(
      "NO_OF_USER_IN_TILE_TEXT",
      language,
    ),
    CHANGE_AUDIO_SOURCE: Translator.translate("CHANGE_AUDIO_SOURCE", language),
    CHANGE_AUDIO_SOURCE_DESC: Translator.translate(
      "CHANGE_AUDIO_SOURCE_DESC",
      language,
    ),
    AUDIO_INPUT_DEVICE_LABEL: Translator.translate(
      "AUDIO_INPUT_DEVICE_LABEL",
      language,
    ),
    NO_AUDIO_DEVICE_AVAILABLE: Translator.translate(
      "NO_AUDIO_DEVICE_AVAILABLE",
      language,
    ),
    AUDIO_OUTPUT_DEVICE_LABEL: Translator.translate(
      "AUDIO_OUTPUT_DEVICE_LABEL",
      language,
    ),
    CHANGE_VIDEO_SOURCE: Translator.translate("CHANGE_VIDEO_SOURCE", language),
    CHANGE_VIDEO_SOURCE_DESC: Translator.translate(
      "CHANGE_VIDEO_SOURCE_DESC",
      language,
    ),
    VIDEO_DEVICES_LABEL: Translator.translate("VIDEO_DEVICES_LABEL", language),
    FULL_SCREEN: Translator.translate("FULL_SCREEN", language),
    EXIT_FULL_SCREEN: Translator.translate("EXIT_FULL_SCREEN", language),
    CHANGE_LAYOUT: Translator.translate("CHANGE_LAYOUT", language),
    AUDIO_SETTING: Translator.translate("AUDIO_SETTING", language),
    VIDEO_SETTING: Translator.translate("VIDEO_SETTING", language),
    USER_LIST: Translator.translate("USER_LIST", language),
    MORE: Translator.translate("MORE", language),
    MUTE_USER: Translator.translate("MUTE_USER", language),
    PIN_USER: Translator.translate("PIN_USER", language),
    UNPIN_USER: Translator.translate("UNPIN_USER", language),
    UN_MUTE_USER: Translator.translate("UN_MUTE_USER", language),
    MUTED: Translator.translate("MUTED", language),
    YOU_HAVE_BLOCKED: Translator.translate("YOU_HAVE_BLOCKED", language),
    NOT_POSSIBLE_TO_SEND_MESSAGES: Translator.translate(
      "NOT_POSSIBLE_TO_SEND_MESSAGES",
      language,
    ),
    IOS_BROWSER_WARNING: Translator.translate("IOS_BROWSER_WARNING", language),
    PEOPLE_TEXT: Translator.translate("PEOPLE_TEXT", language),
    SPOTLIGHT_DETAIL_TEXT1: Translator.translate(
      "SPOTLIGHT_DETAIL_TEXT1",
      language,
    ),
    SPOTLIGHT_DETAIL_TEXT2: Translator.translate(
      "SPOTLIGHT_DETAIL_TEXT2",
      language,
    ),
    SPOTLIGHT_DETAIL_TEXT3: Translator.translate(
      "SPOTLIGHT_DETAIL_TEXT3",
      language,
    ),
    SPOTLIGHT_DETAIL_TEXT4: Translator.translate(
      "SPOTLIGHT_DETAIL_TEXT4",
      language,
    ),
  };
};
