import React from "react";
import "./SectionTitle.scss";
import { PlusCircleOutlined } from "@ant-design/icons";

interface ISectionTitle {
  title: string;
  withAddIcon?: boolean;
  onAddClick?: () => void;
  iconColor?: string;
  logo?: string;
}

export const SectionTitle: React.FC<ISectionTitle> = ({
  title,
  withAddIcon = false,
  onAddClick,
  iconColor,
  logo,
}) => {
  return (
    <div className={"section-title"}>
      <div>{title}</div>
      {withAddIcon && (
        <PlusCircleOutlined
          onClick={onAddClick}
          style={iconColor ? { color: iconColor } : {}}
        />
      )}
      {logo && <img src={logo} alt={"logo"} className={"section-title-logo"} />}
    </div>
  );
};
