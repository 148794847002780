import React from "react";
import { TableProps } from "antd";
import { CompanyData } from "../../../../services/company";

export const getColumns = (): TableProps<CompanyData>["columns"] => [
  {
    title: "სახელი",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "ქალაქი",
    dataIndex: "cityName",
    key: "cityName",
  },
  {
    title: "ელ-ფოსტა",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "მისამართი",
    dataIndex: "actualAddress",
    key: "actualAddress",
  },
  {
    title: "იურიდიული მისამართი",
    dataIndex: "legalAddress",
    key: "legalAddress",
  },
  {
    title: "საიდენტიფიკაციო კოდი",
    dataIndex: "taxCode",
    key: "taxCode",
  },
];
