import { apiClient } from "./apiClient";
import { useQuery } from "react-query";
import { GeneralResponseType } from "./generalTypes";

export interface IReviewData {
  id: string;
  rating: number;
  comment: string;
  commenterName: string;
  commenterAvatar: string;
  status: number;
}

export interface IReview {
  data: IReviewData[];
  totalItemCount: number;
  page: number;
  offset: number;
  pageCount: number;
}

type GetReviewsResp = GeneralResponseType<IReview>;

export const getReviews = (data: any): Promise<GetReviewsResp> => {
  const [_key, { currentPage, pageSize, reviewStatus }] = data.queryKey;
  return apiClient
    .post("/review/filter", {
      pageNumber: currentPage,
      pageSize: pageSize,
      status: reviewStatus,
    })
    .then((resp) => resp.data);
};

export const useGetReviews = (
  currentPage: number,
  pageSize: number,
  reviewStatus: number,
) => {
  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ["review-filter", { currentPage, pageSize, reviewStatus }],
    queryFn: getReviews,
  });
  return {
    isLoading,
    reviews: data?.data,
    status: data?.status,
    refetch,
    isRefetching,
  };
};

interface IUpdateReviewStatusReq {
  id: string;
  status: number;
}

export const updateReviewStatus = (
  data: IUpdateReviewStatusReq,
): Promise<any> =>
  apiClient.post("/review/update", data).then((resp) => resp.data);
