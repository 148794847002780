import { apiClient, businessApiClient } from "./apiClient";
import { useMutation, useQuery, UseQueryOptions } from "react-query";
import {
  BusinessTaskStatus,
  GeneralResponseType,
  IOption,
} from "./generalTypes";
import qs from "qs";

export interface ITaskData {
  id: string;
  taskStatus: number;
  title: string;
  description: string;
  clientId: string;
  clientName: string;
  doerId: string;
  address: string;
  city: string;
  createdDateTime: string;
}

export interface ITask {
  data: ITaskData[];
  totalItemCount: number;
  page: number;
  offset: number;
  pageCount: number;
}

type GetTasksResp = GeneralResponseType<ITask>;

export const getTasks = (
  currentPage: number,
  pageSize: number,
  taskStatuses: Array<string | number>,
): Promise<GetTasksResp> => {
  // const [_key, {currentPage, pageSize, taskStatuses}] = data.queryKey;
  return apiClient
    .post("/task/filter", {
      pageNumber: currentPage,
      pageSize: pageSize,
      taskStatuses: taskStatuses,
    })
    .then((resp) => resp.data);
};

export const useGetTasks = (
  currentPage: number,
  pageSize: number,
  taskStatuses: Array<string | number>,
) => {
  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ["task-filter", currentPage, pageSize, taskStatuses],
    queryFn: () => getTasks(currentPage, pageSize, taskStatuses),
    keepPreviousData: true,
  });
  return {
    isLoading,
    tasks: data?.data,
    status: data?.status,
    refetch,
    isRefetching,
  };
};

type File = {
  fileId: string;
  extension: string;
  url: string;
  key: string;
  description: string;
};

interface ICreateTaskReq {
  // adminId: string,
  Title: string;
  ClientId: string;
  Address: string;
  CityId: number;
  Description: string;
  WemplerTaskSenderType: number;
  InternalComment: string;
  Files: File[];
  // splitShare: number
}

export const createTask = (data: ICreateTaskReq): Promise<string> =>
  apiClient
    .post("/task/create", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((resp) => resp.data);

export interface ITaskDoer {
  //doer in task details
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  avatar: string;
  aboutMe: string;
  //doer active?
  //doer available days?
  reviews: [
    {
      id: string;
      rating: number;
      comment: string;
      commenterName: string;
      commenterAvatar: string;
    },
  ];
  isTrusted: boolean;
  doerType: number;
  companyName: string;
  companyImageUrl: string;
}
export interface ITaskDetails {
  id: string;
  //clinet phone number?
  adminId: string;
  taskStatus: number;
  title: string;
  description: string;
  clientId: string;
  clientName: string;
  address: string;
  city: IOption;
  internalComment: string;
  proposedStartTime: number;
  proposedDuration: number;
  phoneNumber: string;
  doer: ITaskDoer;
  price: number;
  transactionId: string;
  transactionUrl: string;
  actualStartTime: number;
  cancellationReason: {
    id: number;
    name: string;
    isTerminating: boolean;
  };
  isDirectTransfer: boolean;
  images: string[];
  createdTimestamp: string;
}

type GetTaskDetailsResp = GeneralResponseType<ITaskDetails>;

export const getTaskDetails = (
  data: any,
): Promise<GetTaskDetailsResp> | null => {
  const [_key, { id }] = data.queryKey;
  if (id) {
    return apiClient.get(`/task/${id}`).then((resp) => resp.data);
  }
  return null;
};

export const useGetTaskDetails = (id?: number | string) => {
  const { data, isLoading, refetch } = useQuery(
    ["task-id", { id }],
    getTaskDetails,
  );
  return {
    isLoading,
    task: data?.data,
    status: data?.status,
    refetch,
  };
};

interface IRejectTaskReq {
  taskId: string;
  reason: number;
  cancel: boolean;
}

export const rejectTask = (data: IRejectTaskReq): Promise<string> =>
  apiClient.post("/task/rejectOffer", data).then((resp) => resp.data);

interface IAssignTaskReq {
  taskId: string;
  doerId: string;
  price: number;
  duration: number;
  proposedStartTime: number;
  splitShare: number;
}

type AssignTaskResp = {
  success: boolean;
};

export const assignTask = (data: IAssignTaskReq): Promise<AssignTaskResp> =>
  apiClient.post("/task/assign", data).then((resp) => resp.data);

export const useAssignTask = (
  onSuccess?: (data: AssignTaskResp) => void,
  onError?: () => void,
) => {
  const { isLoading, mutate } = useMutation(assignTask, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};

interface ITaskId {
  taskId: string;
}

export const startTask = (data: ITaskId): Promise<string> =>
  apiClient.post("/task/start", data).then((resp) => resp.data);

export const forceCompleteTask = (data: ITaskId): Promise<string> =>
  apiClient.post("/task/forceComplete", data).then((resp) => resp.data);

type ClientCompleteTaskReq = {
  taskId: string;
  clientId: string;
};

export const forceClientCompleteTask = (
  data: ClientCompleteTaskReq,
): Promise<void> =>
  apiClient
    .post("/task/forceClientCompleteTask", data)
    .then((resp) => resp.data);

export const acceptTaskOffer = (data: ITaskId): Promise<string> =>
  apiClient.post("/task/acceptOffer", data).then((resp) => resp.data);

export interface IUpdateTask {
  taskId: string;
  status?: number;
  title?: string;
  address?: string;
  cityId?: number;
  description?: string;
  senderType?: number;
  internalComment?: string;
  proposedStartTime?: number;
  proposedDuration?: number;
  doerId?: string;
  price?: number;
  transactionId?: string;
  transactionUr?: string;
}

export const updateTask = (data: IUpdateTask): Promise<void> =>
  apiClient
    .post("/task/update", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((resp) => resp.data);

//POST update business task

type UpdateBusinessTaskReq = {
  taskId: string;
  address?: string;
  title?: string;
  description?: string;
  doerId?: string;
  price?: number;
};

export const updateBusinessTask = (
  data: UpdateBusinessTaskReq,
): Promise<void> =>
  businessApiClient.post("/task/update", data).then((resp) => resp.data);

export const useUpdateBusinessTask = (
  onSuccess?: () => void,
  onError?: () => void,
) => {
  const { isLoading, mutate } = useMutation(updateBusinessTask, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};

type Category = {
  id: number;
  name: string;
};

type GetTaskCategoriesResp = GeneralResponseType<Category[]>;

const getCities = (): Promise<GetTaskCategoriesResp> =>
  apiClient.get("/task/categories").then((resp) => resp.data);

export const useGetTaskCategories = () => {
  const { data, isLoading } = useQuery(["task-categories"], getCities);
  return {
    isLoading,
    data: data?.data,
    status: data?.status,
  };
};

type PresetTask = {
  presetId: number;
  iconUrl: string;
  name: string;
  shortDescription: string;
  categoryId: number;
  colorHex: string;
};

type GetAllPresetTasksResp = GeneralResponseType<PresetTask[]>;

const getAllPresetTasks = (): Promise<GetAllPresetTasksResp> =>
  apiClient.get("/task/allPresetTasks").then((resp) => resp.data);

export const useGetAllPresetTasks = () => {
  const { data, isLoading } = useQuery(
    ["task-all-preset-tasks"],
    getAllPresetTasks,
  );
  return {
    isLoading,
    data: data?.data,
    status: data?.status,
  };
};

//api/v1/task/all

export type BusinessTaskData = {
  id: string;
  status: BusinessTaskStatus;
  senderUserId: string;
  companyId: string;
  address: string;
  title: string;
  description: string;
  doerId: string | null;
  price: string | null;
};

type BusinessTasks = {
  data: BusinessTaskData[];
  totalItemCount: number;
  page: number;
  offset: number;
  pageCount: number;
};

type BusinessTasksResp = {
  tasksList: BusinessTasks;
};

type BusinessTasksParams = {
  companyId?: string;
  searchText?: string;
  statuses?: number[];
  creationStart?: string;
  creationEnd?: string;
  completionStart?: string;
  completionEnd?: string;
  pageNumber?: number;
  pageSize?: number;
};
// type GetAllTaskResp = GeneralResponseType<BusinessTasks>;

export const getBusinessTasks = (
  params?: BusinessTasksParams,
): Promise<BusinessTasksResp> =>
  businessApiClient
    .get(`/task/all`, {
      params,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    })
    .then((resp) => resp.data);

export const useGetBusinessTasks = (
  params?: BusinessTasksParams,
  options?: UseQueryOptions<BusinessTasksResp>,
) => {
  const pageNumber = params?.pageNumber;
  const pageSize = params?.pageSize;
  const statuses = params?.statuses;
  const companyId = params?.companyId;
  const searchText = params?.searchText;

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      "task-all",
      pageNumber,
      pageSize,
      statuses,
      companyId,
      searchText,
    ],
    queryFn: () => getBusinessTasks(params),
    ...options,
  });
  return {
    isLoading,
    data: data?.tasksList?.data,
    totalItemCount: data?.tasksList?.totalItemCount,
    page: data?.tasksList?.page,
    offset: data?.tasksList?.offset,
    pageCount: data?.tasksList?.pageCount,
    refetch,
  };
};

//api/v1/task/{wemplerTaskId}

type BusinessTaskCompany = {
  id: string;
  name: string;
  number: string;
  city: string;
};

type BusinessTaskSender = {
  name: string;
  surname: string;
  phoneNumber: string;
  email: string;
};

export type BusinessTaskDetails = {
  status: BusinessTaskStatus;
  title: string;
  description: string;
  address: string;
  creationDate: Date;
  lastUpdatedOn: Date;
  price: number | null;
  company: BusinessTaskCompany;
  sender: BusinessTaskSender;
};

export const getBusinessTaskDetails = (
  id?: string,
): Promise<BusinessTaskDetails> =>
  businessApiClient.get(`/task/${id}`).then((resp) => resp.data);

export const useGetBusinessTaskDetails = (
  id?: string,
  options?: UseQueryOptions<BusinessTaskDetails>,
) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["task-wemplertaskid", id],
    queryFn: () => getBusinessTaskDetails(id),
    ...options,
  });
  return {
    data,
    isLoading,
    refetch,
  };
};

type AssignBusinessTaskReq = {
  taskId: string;
  doerId: string;
  price: number;
};

type AssignBusinessTaskResp = {
  success: boolean;
};

export const assignBusinessTask = (
  data: AssignBusinessTaskReq,
): Promise<AssignBusinessTaskResp> =>
  businessApiClient.post("/task/assign", data).then((resp) => resp.data);

export const useAssignBusinessTask = (
  onSuccess?: (data: AssignTaskResp) => void,
  onError?: () => void,
) => {
  const { isLoading, mutate } = useMutation(assignBusinessTask, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};

type CancelBusinessTaskResp = {
  success: boolean;
};

export const cancelBusinessTask = (
  taskId?: string,
): Promise<CancelBusinessTaskResp> =>
  businessApiClient.post(`/task/cancel/${taskId}`).then((resp) => resp.data);

export const useCancelBusinessTask = (
  onSuccess?: (data: CancelBusinessTaskResp) => void,
  onError?: () => void,
) => {
  const { isLoading, mutate } = useMutation(cancelBusinessTask, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};

type FinishBusinessTaskResp = {
  success: boolean;
};

export const finishBusinessTask = (
  taskId?: string,
): Promise<FinishBusinessTaskResp> =>
  businessApiClient.post(`/task/finish/${taskId}`).then((resp) => resp.data);

export const useFinishBusinessTask = (
  onSuccess?: (data: FinishBusinessTaskResp) => void,
  onError?: () => void,
) => {
  const { isLoading, mutate } = useMutation(finishBusinessTask, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};
