import React, { useEffect, useState } from "react";
import { BusinessTaskData } from "../../../../services/task";
import { useDebouncedCallback } from "use-debounce";

type FilteredBusinessTasks = BusinessTaskData[] | undefined;

type BusinessTaskFilters = {
  filteredBusinessTasks: FilteredBusinessTasks;
};

export const useBusinessTaskFilters = (
  businessTasks: BusinessTaskData[] | undefined,
): BusinessTaskFilters => {
  const [filteredBusinessTasks, setFilteredBusinessTasks] =
    useState<FilteredBusinessTasks>(businessTasks);

  useEffect(() => {
    if (businessTasks) setFilteredBusinessTasks(businessTasks);
  }, [businessTasks]);

  return {
    filteredBusinessTasks,
  };
};
