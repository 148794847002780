import React from "react";
import "./Header.scss";
import { Header as AntdHeader } from "antd/es/layout/layout";
import { LogoutOutlined } from "@ant-design/icons";
import { removeToken } from "../../../utils/helpers";
import { useNavigate } from "react-router";

export const Header: React.FC = () => {
  const navigate = useNavigate();

  const handleLogOutClick = () => {
    removeToken();
    navigate("/auth");
  };

  return (
    <AntdHeader className="header" style={{ padding: 0 }}>
      <LogoutOutlined className={"logout-button"} onClick={handleLogOutClick} />
    </AntdHeader>
  );
};
