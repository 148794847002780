import { CometChat } from "@cometchat-pro/chat";
import { useState } from "react";

export const useCometChatLogin = () => {
  const cometChat_authKey = process.env.REACT_APP_COMETCHAT_AUTH_KEY;

  const [isCometChatUserLoggedIn, setIsCometChatUserLoggedIn] =
    useState<boolean>(false);

  const loginCometChatUser = (uid: string) => {
    CometChat.login(uid, cometChat_authKey).then(
      (user) => {
        setIsCometChatUserLoggedIn(true);
        // console.log("Login Successful:", { user });
      },
      (error) => {
        setIsCometChatUserLoggedIn(false);
        console.log("Login failed with exception:", { error });
      },
    );
  };

  return { loginCometChatUser, isCometChatUserLoggedIn };
};
