import React from "react";
import "antd/dist/reset.css";
import "./App.scss";
// import "antd/dist/antd.min.css";
// import "antd/dist/antd.css";
import { Auth } from "./modules";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  BusinessTasks,
  Chat,
  Companies,
  Doers,
  Tasks,
} from "./modules/Content";
import { Doer } from "./modules/Content/Doers";
import { ProtectedRoute } from "./router/ProtectedRoute/ProtectedRoute";
import { Layout } from "./modules/Layout/Layout";
import { TaskDetails } from "./modules/Content/Tasks";
import { Reviews } from "./modules/Content/Reviews";
import { BusinessTaskDetails } from "./modules/Content/BusinessTasks";

function App() {
  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        }
      >
        <Route path="/" element={<Navigate to="/tasks" replace />} />

        <Route path="tasks">
          <Route index element={<Tasks />} />
          <Route path=":status">
            <Route index element={<Tasks />} />
            <Route path=":id" element={<TaskDetails />} />{" "}
          </Route>
        </Route>

        <Route path="doers">
          <Route index element={<Doers withAddDoer />} />
          <Route path=":id" element={<Doer />} />
        </Route>

        <Route path="reviews" element={<Reviews />} />
        <Route path="chat" element={<Chat />} />
        <Route path="companies" element={<Companies />} />

        <Route path="business">
          <Route index element={<BusinessTasks />} />
          <Route path=":status">
            <Route index element={<BusinessTasks />} />
            <Route path=":id" element={<BusinessTaskDetails />} />
          </Route>
        </Route>

        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>

      <Route path="/auth" element={<Auth />} />
    </Routes>
  );
}

export default App;
