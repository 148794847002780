import React, { useState } from "react";
import "./Review.scss";
import { IReviewData, updateReviewStatus } from "../../../../services/review";
import { message, Rate } from "antd";
import EmptyAvatar from "../../../../assets/png/EmptyAvatar.png";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useMutation } from "react-query";
import { ConfirmationModal } from "../../Tasks/ActionModals";

interface IReviewItem {
  review: IReviewData;
  refetchReviews: () => Promise<any>;
}

export const Review: React.FC<IReviewItem> = ({ review, refetchReviews }) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<{
    open: boolean;
    confirmationType: "reject" | "approve";
  }>({
    open: false,
    confirmationType: "reject",
  });
  const {
    isLoading: updateReviewStatusLoading,
    mutate: mutateUpdateReviewStatus,
  } = useMutation(updateReviewStatus, {
    onSuccess: () => {
      refetchReviews().catch((err: any) => message.error(err));
    },
  });

  const handleStatusUpdate = (status: number) => {
    if (review.id) {
      mutateUpdateReviewStatus({
        id: review.id,
        status,
      });
    }
  };

  return (
    <div className={"review-item-container"}>
      <div className={"review-item-left-wrapper"}>
        <div className={"reviewer-image-wrapper"}>
          <img
            className="reviewer-image"
            src={review.commenterAvatar || EmptyAvatar}
            alt={""}
          />
        </div>
        <div className={"review-details-wrapper"}>
          <div className={"reviewer-name-wrapper"}>
            <div className={"reviewer-name"}>{review.commenterName}</div>
            <Rate
              className={"reviewer-stars"}
              disabled
              defaultValue={review.rating}
            />
          </div>
          <div className={"reviewer-comment"}>
            {review.comment || "(კომენტარი ცარიელია)"}
          </div>
        </div>
      </div>
      {review.status === 1 && (
        <div className={"review-right-wrapper"}>
          <CloseCircleOutlined
            className={"no-icon"}
            onClick={() =>
              setConfirmationModalOpen({
                open: true,
                confirmationType: "reject",
              })
            }
          />
          <CheckCircleOutlined
            className={"yes-icon"}
            onClick={() =>
              setConfirmationModalOpen({
                open: true,
                confirmationType: "approve",
              })
            }
          />
          {confirmationModalOpen.confirmationType === "reject" ? (
            <ConfirmationModal
              modalOpen={confirmationModalOpen.open}
              setModalOpen={(value: boolean) =>
                setConfirmationModalOpen((prev) => ({ ...prev, open: value }))
              }
              title={"შეფასების გაუქმება"}
              confirmationText={"ნამდვილად გსურთ შეფასების გაუქმება?"}
              onSubmit={() => handleStatusUpdate(2)}
              color={"#FF3100"}
              isLoading={updateReviewStatusLoading}
            />
          ) : (
            <ConfirmationModal
              modalOpen={confirmationModalOpen.open}
              setModalOpen={(value: boolean) =>
                setConfirmationModalOpen((prev) => ({ ...prev, open: value }))
              }
              title={"შეფასების დადასტურება"}
              confirmationText={"ნამდვილად გსურთ შეფასების დადასტურება?"}
              onSubmit={() => handleStatusUpdate(3)}
              color={"#06D672"}
              isLoading={updateReviewStatusLoading}
            />
          )}
        </div>
      )}
    </div>
  );
};
