import React, { useEffect } from "react";
import "./EditBusinessTaskDescriptionModal.scss";
import { Col, Form, FormInstance, Input, Modal, Row } from "antd";
import TextArea from "antd/es/input/TextArea";

type FieldValues = {
  description: string;
};

type EditBusinessTaskDescriptionModalProps = {
  modalOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  initialValues: FieldValues;
  onSubmit: (values: any, afterSubmit?: () => void) => void;
};

export const EditBusinessTaskDescriptionModal: React.FC<
  EditBusinessTaskDescriptionModalProps
> = ({ modalOpen, onClose, isLoading, initialValues, onSubmit }) => {
  const [form]: [FormInstance<FieldValues>] = Form.useForm();

  useEffect(() => {
    if (modalOpen) {
      form.setFieldsValue({ ...initialValues });
    }
  }, [form, modalOpen]);

  const onFinish = (values: FieldValues) => {
    onSubmit(values, onClose);
  };

  return (
    <Modal
      open={modalOpen}
      className={"edit-business-task-description-modal"}
      title={"აღწერის რედაქტირება"}
      onOk={form.submit}
      okText={"დასტური"}
      cancelText={"დახურვა"}
      onCancel={onClose}
      maskClosable
      destroyOnClose
      okButtonProps={{
        loading: isLoading,
      }}
      centered
      afterClose={() => {
        form.resetFields();
      }}
    >
      <Form form={form} onFinish={onFinish} initialValues={initialValues}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name={"description"}>
              <TextArea
                autoSize={{ minRows: 3, maxRows: 5 }}
                placeholder={"დავალების აღწერა"}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
