import React, { useMemo, useState } from "react";
import "./FindDoerSkillsContent.scss";
import { Checkbox } from "antd";
import {
  UseGetBusinessSkillsResp,
  useGetSkills,
  UseGetSkillsResp,
} from "../../../services/catalog";

type FindDoerSkillsContentProps = {
  onSkillsSelect: (selectedSkills: Array<number | string>) => void;
  getSkills?: () => UseGetSkillsResp | UseGetBusinessSkillsResp;
};

export const FindDoerSkillsContent: React.FC<FindDoerSkillsContentProps> = ({
  onSkillsSelect,
  getSkills = useGetSkills,
}) => {
  const { skills } = getSkills();

  const skillOptions = useMemo(() => {
    if (skills) {
      return skills.map((item) => ({ label: item.name, value: item.id }));
    }
    return [];
  }, [skills]);

  return (
    <div className={"find-doer-skills-wrapper"}>
      <Checkbox.Group
        className={"find-doer-checkbox-group"}
        options={skillOptions}
        onChange={onSkillsSelect}
      />
    </div>
  );
};
