import React, { useEffect, useMemo, useState } from "react";
import "./AddDoerModal.scss";
import { Checkbox, Col, Form, Input, message, Modal, Radio, Row } from "antd";
import { CustomSelect } from "../../../../../components/CustomSelect/CustomSelect";
import TextArea from "antd/es/input/TextArea";
import { useGetCities } from "../../../../../services";
import { formValidation } from "../../../../../utils/formValidation";
import { doerTypes } from "../../../../../services/generalTypes";
import { useMutation } from "react-query";
import { MultipleSelect } from "../../../../../components";
import { useGetSkills } from "../../../../../services/catalog";
import { createDoer } from "../../../../../services/doer";

interface IAddDoerModal {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetchDoers: () => Promise<any>;
}

export const AddDoerModal: React.FC<IAddDoerModal> = ({
  modalOpen,
  setModalOpen,
  refetchDoers,
}) => {
  const [form] = Form.useForm();
  const { isLoading: citiesLoading, cities } = useGetCities();
  const { isLoading: skillsLoading, skills } = useGetSkills();
  const [doerType, setDoerType] = useState(1);
  const { isLoading: createDoerLoading, mutate: mutateCreateDoer } =
    useMutation(createDoer, {
      onSuccess: () => {
        refetchDoers()
          .then(() => {
            setModalOpen(false);
          })
          .catch((err) => {
            message.error(err);
          });
      },
    });

  useEffect(() => {
    if (!modalOpen) {
      form.resetFields();
    } else {
      form.setFieldsValue({ doerType: 1 });
    }
  }, [form, modalOpen]);

  const renderDoerTypeFields = useMemo(() => {
    switch (doerType) {
      case 2:
        return (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name={"companyName"} rules={formValidation.required}>
                <Input
                  className={"add-doer-input"}
                  placeholder={"კომპანიის სახელი"}
                />
              </Form.Item>
            </Col>
          </Row>
        );
      default:
        return (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name={"firstName"} rules={formValidation.required}>
                <Input className={"add-doer-input"} placeholder={"სახელი"} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={"lastName"} rules={formValidation.required}>
                <Input className={"add-doer-input"} placeholder={"გვარი"} />
              </Form.Item>
            </Col>
          </Row>
        );
    }
  }, [doerType]);

  const handleSubmit = (values: any) => {
    mutateCreateDoer({
      firstName: "",
      lastName: "",
      ...values,
      avatar: null,
      companyImageUrl: null,
    });
  };

  const handleValuesChange = (changedValue: any) => {
    if (changedValue?.doerType) {
      setDoerType(changedValue.doerType);
    }
  };

  return (
    <Modal
      open={modalOpen}
      className={"add-doer-modal"}
      title={"ახალი შემსრულებლის დამატება"}
      onOk={form.submit}
      okText={"დამატება"}
      cancelText={"გაუქმება"}
      onCancel={() => setModalOpen(false)}
      maskClosable
      destroyOnClose
      centered
      okButtonProps={{
        loading: createDoerLoading,
      }}
    >
      <Form
        form={form}
        className={"add-doer-form"}
        onFinish={handleSubmit}
        onValuesChange={handleValuesChange}
      >
        <Row>
          <Col span={24}>
            <Form.Item name={"doerType"} className={"doer-radio-form-item"}>
              <Radio.Group className={"add-doer-radio-group"}>
                {doerTypes.map((item) => {
                  return (
                    <Radio key={item.id} value={item.id}>
                      {item.name}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        {renderDoerTypeFields}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name={"phoneNumber"} rules={formValidation.required}>
              <Input
                className={"add-doer-input"}
                placeholder={"ტელეფონის ნომერი"}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={"cityId"} rules={formValidation.required}>
              <CustomSelect
                options={cities}
                placeholder={"ქალაქი"}
                loading={citiesLoading}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name={"availability"} rules={formValidation.required}>
              <Input
                className={"add-doer-input"}
                placeholder={"ხელმისაწვდომობა"}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={"iban"} rules={formValidation.required}>
              <Input
                className={"add-doer-input"}
                placeholder={"ბანკის ანგარიში"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name={"aboutMe"} rules={formValidation.required}>
              <TextArea
                className={"add-doer-textarea"}
                autoSize={false}
                rows={3}
                placeholder={"შემსრულებლის შესახებ"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name={"note"}>
              <TextArea
                className={"add-doer-textarea"}
                autoSize={false}
                rows={3}
                placeholder={"კომენტარი"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name={"skillIds"}>
              <MultipleSelect
                placeholder="კატეგორია"
                options={skills}
                loading={skillsLoading}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name={"isTrusted"} valuePropName={"checked"}>
              <Checkbox>სანდო შემსრულებელი</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
