import React, { useEffect, useState } from "react";
import "./EditDoerModal.scss";
import { Divider, Modal } from "antd";
import { ITaskDoer } from "../../../../../services/task";
import { DoerItem } from "../../../../../components";
import { useNavigate } from "react-router";
import { Doers } from "../../../Doers";
import { IDoer } from "../../../../../services/doer";

interface IEditDoerModal {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentDoer: ITaskDoer;
  onSubmit?: (values: any, afterSubmit?: () => void) => void;
  loading?: boolean;
}

export const EditDoerModal: React.FC<IEditDoerModal> = ({
  modalOpen,
  setModalOpen,
  currentDoer,
  onSubmit,
  loading,
}) => {
  const [doer, setDoer] = useState<IDoer | ITaskDoer>();
  const navigate = useNavigate();

  useEffect(() => {
    if (modalOpen) {
      setDoer(currentDoer);
    }
  }, [currentDoer, modalOpen]);

  useEffect(() => {
    if (!modalOpen) {
      setDoer(undefined);
    }
  }, [modalOpen]);

  const handleSubmit = () => {
    if (doer?.id) {
      onSubmit?.({ doerId: doer.id }, () => setModalOpen(false));
    }
  };

  return (
    <Modal
      width={700}
      open={modalOpen}
      className={"edit-doer-modal"}
      title={"შემსრულებლის შეცვლა"}
      onOk={handleSubmit}
      okText={"არჩევა"}
      cancelText={"დახურვა"}
      onCancel={() => setModalOpen(false)}
      maskClosable
      destroyOnClose
      okButtonProps={{
        loading: loading,
      }}
    >
      {doer ? (
        <DoerItem
          doer={doer}
          onDoerClick={(doerId) => navigate(`/doers/${doerId}`)}
        />
      ) : (
        <></>
      )}
      <Divider />
      <Doers
        pageSize={3}
        onDoerClick={(id, selectedDoer) => {
          setDoer(selectedDoer);
        }}
      />
    </Modal>
  );
};
