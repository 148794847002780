import React from "react";
import "./Chat.scss";
import { CometChatUI } from "../../../cometchat-pro-react-ui-kit/CometChatWorkspace/src";

export const Chat = () => {
  return (
    <div className={"chat-wrapper"}>
      <CometChatUI />
    </div>
  );
};
