import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { API_URL } from "../services/apiClient";
import { useEffect, useState } from "react";

export const useDesktopNotifications = () => {
  const [connection, setConnection] = useState<HubConnection>();

  const requestNotificationsPermission = () => {
    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notifications");
    } else if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
  };

  //CONNECT TO SIGNALR
  const createHubConnection = () => {
    const hubConnection = new HubConnectionBuilder()
      .withUrl(`${API_URL}/hubs/notifications`)
      .withAutomaticReconnect()
      .build();
    setConnection(hubConnection);
  };

  const connectToHub = () => {
    requestNotificationsPermission();
    createHubConnection();
  };

  const showNotification = (message: string) => {
    if ("Notification" in window) {
      if (Notification.permission === "granted") {
        new Notification("ახალი შეტყობინება", {
          body: message,
          icon: process.env.PUBLIC_URL + "/assets/logo152.png",
          dir: "ltr",
        });
      }
    }
  };

  useEffect(() => {
    if (connection) {
      try {
        connection
          .start()
          .then(() => {
            connection.on("ReceiveMessage", (message) => {
              showNotification(message);
            });
          })
          .catch((err) => {
            console.log(`Error: ${err}`);
          });
      } catch (error) {
        console.log(error as Error);
      }
    }

    return () => {
      connection?.stop();
    };
  }, [connection]);

  return {
    connectToHub,
  };
};
