export const formValidation = {
  required: [
    {
      required: true,
      message: "ველი სავალდებულოა",
    },
  ],
  phoneNumber: [
    {
      pattern: /^5[0-9]{8}/,
      message: "ტელეფონის ნომერი არასწორია",
    },
  ],
};
