import React, { useMemo, useState } from "react";
import "./Tasks.scss";
import { Button, Pagination, Spin } from "antd";
import { useNavigate, useParams } from "react-router";
import { TaskItem } from "../../../components";
import { PlusOutlined } from "@ant-design/icons";
import { AddTaskModal } from "./ActionModals";
import { useGetTasks } from "../../../services/task";
import {
  TASK_STATUS_TYPES,
  TaskStatus,
  TaskStatusItem,
  taskStatusTypes,
} from "../../../services/generalTypes";
import { TaskStatusButton } from "../../../components/TaskStatusButton/TaskStatusButton";
import {
  getStatusColor,
  getStatusIcon,
  getStatusName,
} from "../../../utils/helpers";

export const Tasks = () => {
  const { status: paramsStatus } = useParams();
  const navigate = useNavigate();
  const [addTaskModalOpen, setAddTaskModalOpen] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 5,
  });

  const statusesArr = useMemo((): TaskStatusItem[] => {
    return taskStatusTypes.slice(0, 5);
  }, [taskStatusTypes]);

  const selectedTab = useMemo((): TaskStatus => {
    if (paramsStatus) {
      const status = statusesArr.find((item) => item.name === paramsStatus);
      if (status) return status.name;
    }
    return TASK_STATUS_TYPES.CREATED;
  }, [paramsStatus, statusesArr]);

  const getTaskStatuses = () => {
    const statusId =
      taskStatusTypes.find((item) => item.name === selectedTab)?.id || 1;
    switch (selectedTab) {
      case TASK_STATUS_TYPES.DONE:
        return [statusId, 6, 7]; //@TODO temporary
      default:
        return [statusId];
    }
  };

  const { tasks, isLoading, refetch, isRefetching } = useGetTasks(
    pagination.currentPage,
    pagination.pageSize,
    getTaskStatuses(),
  );

  const resetPagination = () => {
    setPagination({
      currentPage: 1,
      pageSize: 5,
    });
  };

  return (
    <div className={"tasks-wrapper"}>
      <div className={"statuses-wrapper"}>
        {statusesArr.map((item, index) => {
          const id = item.id;
          const selected = item.name === selectedTab;
          const color = getStatusColor(id);
          const name = getStatusName(id);
          const icon = getStatusIcon(id);
          return (
            <TaskStatusButton
              name={name}
              icon={icon}
              color={color}
              statusItem={item}
              selected={selected}
              key={id}
              onClick={() => {
                resetPagination();
                navigate(`/tasks/${item.name}`);
              }}
            />
          );
        })}
        <Button
          className={"add-new-task-button"}
          onClick={() => setAddTaskModalOpen(true)}
        >
          <PlusOutlined />
        </Button>
        <AddTaskModal
          modalOpen={addTaskModalOpen}
          setModalOpen={setAddTaskModalOpen}
          refetchTasks={refetch}
        />
      </div>
      <div
        className={`tasks-list-wrapper ${
          isLoading || isRefetching ? "loading" : ""
        }`}
      >
        {isLoading || isRefetching ? (
          <Spin />
        ) : (
          tasks?.data?.map((item) => {
            return (
              <TaskItem
                task={item}
                key={item.id}
                onClick={() => navigate(`/tasks/${selectedTab}/${item.id}`)}
              />
            );
          })
        )}
      </div>
      <Pagination
        className={"tasks-list-pagination"}
        current={pagination.currentPage}
        defaultCurrent={1}
        total={tasks?.totalItemCount || 0}
        pageSize={pagination.pageSize}
        pageSizeOptions={[5, 15, 20, 50]}
        onChange={(page) => {
          setPagination((prev) => ({ ...prev, currentPage: page }));
        }}
        onShowSizeChange={(_, size) => {
          setPagination((prev) => ({ ...prev, pageSize: size }));
        }}
      />
    </div>
  );
};
