import React, { useState } from "react";
import "./Auth.scss";
import { Button, Checkbox, Form, Input, message } from "antd";
import { Logo } from "../../assets/svg";
import { sendOtpCode, verifyOtpCode } from "../../services/auth";
import { useMutation } from "react-query";
import { setToken } from "../../utils/helpers";
import { OTPModal } from "./OTPModal/OTPModal";
import { useNavigate } from "react-router";

export const Auth: React.FC = () => {
  const [form] = Form.useForm();
  const [otpModalOpen, setOtpModalOpen] = useState<boolean>(false);
  const [otpError, setOtpError] = useState<boolean>(false);
  const navigate = useNavigate();

  const [verifyData, setVerifyData] = useState<string | null>();

  const { isLoading: sendOtpLoading, mutate: sendOtp } = useMutation(
    sendOtpCode,
    {
      onSuccess: ({ data }) => {
        setVerifyData(data.hash);
      },
      onError: () => {
        message.error("დაფიქსირდა შეცდომა");
      },
    },
  );

  const { isLoading: verifyOtpLoading, mutate: verifyOtp } = useMutation(
    verifyOtpCode,
    {
      onSuccess: ({ data }) => {
        setToken(data.accessToken);
      },
    },
  );

  const handleClick = () => {
    sendOtp(
      {
        phoneNumber: `995${form.getFieldValue("phone")}`,
      },
      {
        onSuccess: () => setOtpModalOpen(true),
      },
    );
  };
  const handleOtpSubmit = (code: string) => {
    if (verifyData) {
      verifyOtp(
        {
          hash: verifyData,
          code,
          phoneNumber: `995${form.getFieldValue("phone")}`,
        },
        {
          onSuccess: () => {
            setOtpModalOpen(false);
            navigate("/tasks");
          },
          onError: () => {
            setOtpError(true);
          },
        },
      );
    }
  };

  return (
    <div className={"auth-layout"}>
      <Form form={form} className={"auth-layout-form"} onFinish={handleClick}>
        <Logo />
        <Form.Item
          name={"phone"}
          rules={[
            {
              required: true,
              message: "ველი სავალდებულოა",
            },
            {
              pattern: /^5[0-9]{8}/,
              message: "ტელეფონის ნომერი არასწორია",
            },
          ]}
        >
          <Input
            type={"tel"}
            className={"auth-input"}
            placeholder={"ტელეფონის ნომერი"}
            onChange={(e) => {
              if (e.target.value?.length > 9) {
                form.setFieldsValue({ phone: e.target.value.slice(0, 9) });
                form.validateFields();
              }
            }}
          />
        </Form.Item>
        <Button
          className={"send-otp-button"}
          loading={sendOtpLoading}
          onClick={form.submit}
        >
          კოდის გამოგზავნა
        </Button>
        <Checkbox className={"remember-me-checkbox"} checked>
          {"დამახსოვრება"}
        </Checkbox>
        {otpModalOpen && (
          <OTPModal
            modalOpen={otpModalOpen}
            setModalOpen={setOtpModalOpen}
            onSubmit={handleOtpSubmit}
            loading={verifyOtpLoading}
            hasError={otpError}
            setOtpError={setOtpError}
            phoneNumber={form.getFieldValue("phone")}
            sendOtp={sendOtp}
          />
        )}
      </Form>
    </div>
  );
};
