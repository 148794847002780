import React, { useState } from "react";
import { Button, message } from "antd";
import {
  BusinessTaskDetails,
  useAssignBusinessTask,
  useCancelBusinessTask,
} from "../../../../services/task";
import { BusinessTask } from "../BusinessTask/BusinessTask";
import { ConfirmationModal, FindDoerModal } from "../../Tasks/ActionModals";
import { FormValues } from "../../../../components/FindDoerModalContent/FindDoerDetailsContent/FindDoerDetailsContent";
import {
  useGetBusinessCities,
  useGetBusinessSkills,
} from "../../../../services/catalog";
import {
  useGetBusinessDoerDetails,
  useGetBusinessDoers,
} from "../../../../services/doer";

type IncomingBusinessTaskWrapperProps = {
  businessTask: BusinessTaskDetails;
  businessTaskId?: string;
  refetchBusinessTask: any;
};

export const IncomingBusinessTaskWrapper: React.FC<
  IncomingBusinessTaskWrapperProps
> = ({ businessTask, businessTaskId, refetchBusinessTask }) => {
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
  const [findDoerModalOpen, setFindDoerModalOpen] = useState<boolean>(false);

  const { mutate: assignBusinessTask, isLoading: assignBusinessTaskLoading } =
    useAssignBusinessTask();

  const { mutate: cancelBusinessTask, isLoading: cancelBusinessTaskLoading } =
    useCancelBusinessTask();

  const onBusinessTaskAssignSuccess = () => {
    setFindDoerModalOpen(false);
    refetchBusinessTask().catch((err: Error) => {
      message.error(err);
    });
  };

  const handleBusinessTaskAssign = (
    formValues: FormValues,
    doerId?: string,
  ) => {
    if (businessTaskId && doerId) {
      assignBusinessTask(
        {
          taskId: businessTaskId,
          doerId,
          price: formValues.price,
        },
        {
          onSuccess: onBusinessTaskAssignSuccess,
          onError: () => {
            message.error("დაფიქსირდა შეცდომა");
          },
        },
      );
    }
  };

  const onCancelTaskSuccess = () => {
    setCancelModalOpen(true);
    refetchBusinessTask().catch((err: Error) => {
      message.error(err);
    });
  };

  const handleCancelTask = () => {
    cancelBusinessTask(businessTaskId, {
      onSuccess: onCancelTaskSuccess,
      onError: () => {
        message.error("დაფიქსირდა შეცდომა");
      },
    });
  };

  return (
    <BusinessTask
      businessTask={businessTask}
      refetchBusinessTask={refetchBusinessTask}
      actionButtons={
        <>
          <Button
            className={"action-button red"}
            onClick={() => setCancelModalOpen(true)}
          >
            {"დავალების გაუქმება"}
          </Button>
          <ConfirmationModal
            modalOpen={cancelModalOpen}
            setModalOpen={setCancelModalOpen}
            title={"დავალების გაუქმება"}
            confirmationText={"ნამდვილად გსურთ დავალების გაუქმება?"}
            onSubmit={handleCancelTask}
            isLoading={cancelBusinessTaskLoading}
          />
          <Button
            className={"action-button blue"}
            onClick={() => setFindDoerModalOpen(true)}
          >
            {"შემსრულებლის მოძებნა"}
          </Button>
          <FindDoerModal
            modalOpen={findDoerModalOpen}
            setModalOpen={setFindDoerModalOpen}
            onAssignTask={handleBusinessTaskAssign}
            assignTaskLoading={assignBusinessTaskLoading}
            hasAdditionalDetails={false}
            getCities={useGetBusinessCities}
            getSkills={useGetBusinessSkills}
            getDoers={useGetBusinessDoers}
            getDoerDetails={useGetBusinessDoerDetails}
          />
        </>
      }
    />
  );
};
