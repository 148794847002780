import React, { useEffect } from "react";
import "./AddCommentModal.scss";
import { Form, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";

interface IAddCommentModal {
  modalOpen: boolean;
  //eslint-disable-next-line
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  color?: string;
  currentComment?: string;
  loading?: boolean;
  onSubmit?: (values: any, afterSubmit?: () => void) => void;
  commentValuePorpName?: "note" | "internalComment";
}

export const AddCommentModal: React.FC<IAddCommentModal> = ({
  modalOpen,
  setModalOpen,
  color,
  currentComment,
  loading,
  onSubmit,
  commentValuePorpName = "internalComment",
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (!modalOpen) {
      form.resetFields();
    }
  }, [modalOpen]);

  useEffect(() => {
    if (currentComment && modalOpen) {
      form.setFieldsValue({
        [commentValuePorpName]: currentComment,
      });
    }
  }, [currentComment, modalOpen]);

  const handleSubmit = (values: any) => {
    onSubmit?.(values, () => setModalOpen(false));
  };

  return (
    <Modal
      open={modalOpen}
      className={"add-comment-modal"}
      title={"შიდა კომენტარის დამატება"}
      onOk={form.submit}
      okText={"დამატება"}
      cancelText={"გაუქმება"}
      onCancel={() => setModalOpen(false)}
      maskClosable
      destroyOnClose
      okButtonProps={{
        style: color ? { backgroundColor: color, borderColor: color } : {},
        loading: loading,
      }}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item name={commentValuePorpName}>
          <TextArea
            className={"add-comment-textarea"}
            rows={4}
            placeholder={"კომენტარი"}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
