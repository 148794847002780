import React from "react";
import { Button, Col, Form, message, Row } from "antd";
import { UserId } from "../../../../../services/auth";
import { useGetCities } from "../../../../../services";
import { useCreateCompany } from "../../../../../services/company";
import { formValidation } from "../../../../../utils/formValidation";
import { CustomSelect } from "../../../../../components/CustomSelect/CustomSelect";
import { CustomInput } from "../../../../../components";

type AddCompanyStepProps = {
  adminUserId: UserId;
  onAddCompanySuccess: () => void;
};

export const AddCompanyStep: React.FC<AddCompanyStepProps> = ({
  adminUserId,
  onAddCompanySuccess,
}) => {
  const form = Form.useFormInstance();
  const { isLoading: citiesLoading, cities } = useGetCities();

  const { mutate: createCompany, isLoading: createCompanyLoading } =
    useCreateCompany();

  const onNext = () => {
    form
      .validateFields()
      .then(() => {
        console.log({ adminUserId });
        if (adminUserId) {
          const values = form.getFieldsValue();
          createCompany(
            { ...values, adminUserId },
            {
              onSuccess: () => {
                message.success("კომპანია წარმატებით შექიმნა");
                onAddCompanySuccess();
              },
            },
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className={"add-company-subtitle"}>
        შეიყვანეთ ინფორმაცია კომპანიის შესახებ
      </div>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name={"name"} rules={formValidation.required}>
            <CustomInput placeholder={"სახელი"} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={"taxCode"} rules={formValidation.required}>
            <CustomInput placeholder={"საიდენტიფიკაციო კოდი"} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name={"legalAddress"} rules={formValidation.required}>
            <CustomInput placeholder={"იურიდიული მისამართი"} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={"actualAddress"} rules={formValidation.required}>
            <CustomInput placeholder={"მისამართი"} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name={"cityId"} rules={formValidation.required}>
            <CustomSelect
              options={cities}
              placeholder={"ქალაქი"}
              loading={citiesLoading}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={"companyEmail"} rules={formValidation.required}>
            <CustomInput placeholder={"ელ.ფოსტა"} />
          </Form.Item>
        </Col>
      </Row>

      <div className={"buttons-wrapper"}>
        <Button
          htmlType={"submit"}
          className={"next-button"}
          onClick={onNext}
          loading={createCompanyLoading}
        >
          შემდეგი
        </Button>
      </div>
    </>
  );
};
