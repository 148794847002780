import { Col, Form, Input, Row } from "antd";
import { formValidation } from "../../../../../../utils/formValidation";
import { CustomSelect } from "../../../../../../components/CustomSelect/CustomSelect";
import TextArea from "antd/es/input/TextArea";
import { taskSenderTypes } from "../../../../../../services/generalTypes";
import React from "react";
import { useGetCities } from "../../../../../../services";

export const FieldsStep: React.FC = () => {
  const { isLoading: citiesLoading, cities } = useGetCities();

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name={"Title"} rules={formValidation.required}>
            <Input
              className={"add-task-input"}
              placeholder={"დავალების სახელი"}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={"ClientId"} rules={formValidation.required}>
            <Input className={"add-task-input"} placeholder={"კლიენტის ID"} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name={"Address"} rules={formValidation.required}>
            <Input className={"add-task-input"} placeholder={"მისამართი"} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={"CityId"} rules={formValidation.required}>
            <CustomSelect
              options={cities}
              placeholder={"ქალაქი"}
              loading={citiesLoading}
            />
          </Form.Item>
        </Col>
      </Row>
      {/*<Row gutter={16}>*/}
      {/*  <Col span={12}>*/}
      {/*    <Form.Item*/}
      {/*      name={"date"}*/}
      {/*      rules={formValidation.required}*/}
      {/*    >*/}
      {/*      <DatePicker*/}
      {/*        placeholder={"თარიღი"}*/}
      {/*        className={"add-task-date"}*/}
      {/*        onChange={() => console.log("date changed")}*/}
      {/*      />*/}
      {/*    </Form.Item>*/}
      {/*  </Col>*/}
      {/*  <Col span={12}>*/}
      {/*    <Form.Item*/}
      {/*      name={"time"}*/}
      {/*      rules={formValidation.required}*/}
      {/*    >*/}
      {/*      <TimePicker*/}
      {/*        placeholder={"დრო"}*/}
      {/*        className={"add-task-time"}*/}
      {/*        // defaultValue={moment("12:08", "HH:mm")}*/}
      {/*        format={"HH:mm"}*/}
      {/*      />*/}
      {/*    </Form.Item>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      <Row>
        <Col span={24}>
          <Form.Item name={"Description"} rules={formValidation.required}>
            <TextArea
              className={"add-task-textarea"}
              autoSize={false}
              rows={4}
              placeholder={"აღწერა"}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name={"InternalComment"} rules={formValidation.required}>
            <TextArea
              className={"add-task-textarea"}
              autoSize={false}
              rows={4}
              placeholder={"შიდა კომენტარი"}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name={"WemplerTaskSenderType"}
            rules={formValidation.required}
          >
            <CustomSelect options={taskSenderTypes} placeholder={"პლატფორმა"} />
          </Form.Item>
        </Col>
        <Col span={12}>
          {/*<Form.Item*/}
          {/*  name={"splitShare"}*/}
          {/*  rules={formValidation.required}*/}
          {/*>*/}
          {/*  <CustomSelect*/}
          {/*    options={splitShares}*/}
          {/*    placeholder={"საკომისიო"}*/}
          {/*  />*/}
          {/*</Form.Item>*/}
        </Col>
      </Row>
    </>
  );
};
