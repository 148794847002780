import React, { ReactElement } from "react";
import "./ContainerWithEdit.scss";
import { EditOutlined } from "@ant-design/icons";

interface IContainerWithEdit {
  children: ReactElement;
  onEditClick?: () => void;
  className?: string;
  align?: "center" | "flex-start";
}
export const ContainerWithEdit: React.FC<IContainerWithEdit> = ({
  children,
  onEditClick,
  align = "center",
  className = "",
}) => {
  return (
    <div className={`with-edit ${className}`} style={{ alignItems: align }}>
      {children}
      <EditOutlined className={"edit-icon"} onClick={onEditClick} />
    </div>
  );
};
