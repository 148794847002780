import React from "react";
import "./TaskDetails.scss";
import { useParams } from "react-router";
import { useGetTaskDetails } from "../../../../services/task";
import { Spin } from "antd";
import { CreatedTaskWrapper } from "./CreatedTaskWrapper";
import { AssignedTaskWrapper } from "./AssignedTaskWrapper";
import { AcceptedTaskWrapper } from "./AcceptedTaskWrapper";
import { InProgressTaskWrapper } from "./InProgressTaskWrapper";
import { FinishedTaskWrapper } from "./FinishedTaskWrapper";

export const TaskDetails = () => {
  const { id } = useParams();
  const { task, refetch, isLoading } = useGetTaskDetails(id);

  const getTaskComponent = () => {
    if (task && task?.taskStatus) {
      switch (task.taskStatus.toString()) {
        case "2":
          return <AssignedTaskWrapper task={task} refetchTask={refetch} />;
        case "3":
          return <AcceptedTaskWrapper task={task} refetchTask={refetch} />;
        case "4":
          return <InProgressTaskWrapper task={task} refetchTask={refetch} />;
        case "5":
          return <FinishedTaskWrapper task={task} refetchTask={refetch} />;
        default:
          return <CreatedTaskWrapper task={task} refetchTask={refetch} />;
      }
    }
  };

  return (
    <div className={"task-details-container"}>
      <div
        className={`task-details-main-wrapper ${isLoading ? "loading" : ""}`}
      >
        {isLoading ? <Spin /> : getTaskComponent()}
      </div>
    </div>
  );
};
