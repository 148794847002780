import React, { useEffect } from "react";
import "./EditBusinessTaskDetailsModal.scss";
import { Col, Form, FormInstance, Input, Modal, Row } from "antd";

type FieldValues = {
  address: string;
  price: number | null;
};

type EditBusinessTaskDetailsModalProps = {
  modalOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  initialValues: FieldValues;
  onSubmit: (values: any, afterSubmit?: () => void) => void;
};

export const EditBusinessTaskDetailsModal: React.FC<
  EditBusinessTaskDetailsModalProps
> = ({ modalOpen, onClose, isLoading, initialValues, onSubmit }) => {
  const [form]: [FormInstance<FieldValues>] = Form.useForm();

  useEffect(() => {
    if (modalOpen) {
      form.setFieldsValue({ ...initialValues });
    }
  }, [form, modalOpen]);

  const onFinish = (values: FieldValues) => {
    onSubmit(values, onClose);
  };

  return (
    <Modal
      open={modalOpen}
      className={"edit-business-task-details-modal"}
      title={"დეტალების რედაქტირება"}
      onOk={form.submit}
      okText={"დასტური"}
      cancelText={"დახურვა"}
      onCancel={onClose}
      maskClosable
      destroyOnClose
      okButtonProps={{
        loading: isLoading,
      }}
      centered
      afterClose={() => {
        form.resetFields();
      }}
    >
      <Form form={form} onFinish={onFinish} initialValues={initialValues}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name={"address"}>
              <Input placeholder={"მისამართი"} />
            </Form.Item>
          </Col>
        </Row>
        {initialValues.price !== null && (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name={"price"}>
                <Input placeholder={"ფასი"} />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  );
};
