import React from "react";
import { Task } from "../Task/Task";
import { ITaskDetails } from "../../../../services/task";

interface IFinishedTaskWrapper {
  task: ITaskDetails;
  refetchTask: any;
}

export const FinishedTaskWrapper: React.FC<IFinishedTaskWrapper> = ({
  task,
  refetchTask,
}) => {
  return <Task task={task} actionButtons={<></>} />;
};
